import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        geopointsGroups: [],

        geopoints: [],
        geopoint_active: null,
    },
    actions: {
        async setGeopointActive({ commit } , id){
            commit('setGeopointActive' , id)
        },
        async fetchGeopointsGroups({ commit, dispatch, getters }, args) {
            if(!getters.apiToken) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchGeopointsGroups', time: Date.now() / 1000})

            //let fullList = !hasFilter(args)
            const params = getApiProps('geopointsGroups', args)

            this.$api.geopointsgroups.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeopointsGroups', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeopointsGroups', inprogress: false})
                });
        },

        async fetchGeopoints/*all*/({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGeopoints', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('geopoints', args)

                this.$api.geopoints.getAll(params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            if(!fullList) commit('updateGeopoints', response.data)
                            else commit('updateGeopointsFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchGeopoints', inprogress: false})
            });
        },

        async fetchGeopointsAll({dispatch, /*commit,*/ getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchGeopointsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchGeopointsChanged', time: Date.now() / 1000})
            dispatch('fetchGeopoints' , {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.geopoints.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchGeopoints', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                //if(fetch.length) commit('setGeozonesFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchGeopointsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchGeopointsChanged', inprogress: false})
                });
        },
        async fetchGeopointsChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchGeopointsChanged', time: Date.now() / 1000})
            dispatch('fetchGeopoints', args)
                .then(() => {
                    dispatch('fetchGeopoints', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeopointsChanged', inprogress: false})
                });
        },

        async saveGeopoint({ dispatch }, geopoint) {
            let fn = (geopoint.id) ? 'updateGeopoint' : 'createGeopoint'
            return dispatch(fn, geopoint);
        },
        async createGeopoint({ commit, dispatch }, geopoint) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints')
                this.$api.geopoints.create(geopoint, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeopoint', {...response.data, points: geopoint.points})
                            dispatch('fetchGeopointsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateGeopoint({ commit, dispatch }, geopoint) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints')
                this.$api.geopoints.update(geopoint.id, geopoint, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeopoint', {...response.data, points: geopoint.points})
                            dispatch('fetchGeopointsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteGeopoint({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.geopoints.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteGeopoint', id)
                            dispatch('fetchGeopointsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setGeopointActive(state , id){
            state.geopoint_active = id
        },
        updateGeopointsGroups(state, nGroups) {
            if(!state.geopointsGroups.length) {
                state.geopointsGroups = nGroups.map(g => Object.freeze(g))
                return true
            }

            let nId = nGroups.map(ng=> ng.id)
            let groups = state.geopointsGroups.filter( g => nId.includes(g.id) )
            if(groups.length !== state.geopointsGroups.length){
                state.geopointsGroups = groups.map(g => Object.freeze(g))
            }
            nGroups.forEach(function (nGroup) {
                let i = state.geopointsGroups.findIndex(g => (g.id == nGroup.id))
                if(i<0) {
                    state.geopointsGroups.push(Object.freeze(nGroup))
                }else{
                    state.geopointsGroups[i] = Object.freeze({...state.geopointsGroups[i], ...nGroup})
                }
            })
        },
        updateGeopointsFullList(state, nGeopoints) {
            if(!state.geopoints.length) {
                state.geopoints = nGeopoints.map(u => Object.freeze(u))
                return true
            }

            nGeopoints.forEach(function (nGeopoint) {
                let i = state.geopoints.findIndex(u => (u.id == nGeopoint.id))
                if(i<0) {
                    state.geopoints.push(Object.freeze(nGeopoint))
                }else
                if(!state.geopointsFullLoad || state.geopoints[i][changedField] != nGeopoint[changedField]) {
                    state.geopoints[i] = Object.freeze({...state.geopoints[i], ...nGeopoint})
                }
            })

            let nId = nGeopoints.map(nu=> nu.id)
            let removedIds = state.geopoints.filter(g => !nId.includes(g.id)).map(g => g.id)
            removedIds.forEach(removedId => {
                let i = state.geopoints.findIndex(g => (g.id == removedId))
                if (i > 0) {
                    state.geopoints.splice(i, 1)
                }
            })
        },
        updateGeopoints(state, nGeopoints) {
            if(!state.geopoints.length) {
                state.geopoints = nGeopoints.map(u => Object.freeze(u))
                return true
            }

            nGeopoints.forEach(function (nGeopoint) {
                let i = state.geopoints.findIndex(u => (u.id == nGeopoint.id))
                if(i<0) {
                    state.geopoints.push(Object.freeze(nGeopoint))
                }else
                if(!state.geopointsFullLoad || state.geopoints[i][changedField] != nGeopoint[changedField]) {
                    state.geopoints[i] = Object.freeze({...state.geopoints[i], ...nGeopoint})
                }
            })
        },
        updateGeopoint(state, nGeopoint) {
            let i = state.geopoints.findIndex(u => (u.id == nGeopoint.id))
            if(i<0) {
                state.geopoints.push(Object.freeze(nGeopoint))
            }else
            if(!state.geopointsFullLoad || state.geopoints[i][changedField] != nGeopoint[changedField]) {
                state.geopoints[i] = Object.freeze({...state.geopoints[i], ...nGeopoint})
            }
        },
        deleteGeopoint(state, id) {
            let i = state.geopoints.findIndex(g => (g.id == id))
            if(i > 0) {
                state.geopoints.splice(i, 1)
            }
        },
        clearGeopoints(state) {
            state.geopoints = []
            state.geopointsFullLoad = false
        },
    },
    getters: {
        getGeopointActive(state){
            return state.geopoint_active
        },
        geopointsGroups(state){
            return state.geopointsGroups.filter(g => {
                return g.geopoints && g.geopoints.length
            })
        },
        geopointsGroupsByIds(state){
            return state.geopointsGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g
                return groupsByIds
            }, {})
        },
        geopoints(state){
            return state.geopoints
        },
        geopointsByIds(state){
            // return state.geopointsObj
            return state.geopoints.reduce((geopointsByIds, g) => {
                geopointsByIds[g.id] = g
                return geopointsByIds
            }, {})
        },
    }
}
