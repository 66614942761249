<template>
    <div class="filter-template">
        <div class="horizontal-line">
            <div class="no-wrap-items">
                <FieldGroup_k_dropdownlist
                    :select-wrap="'select-wrap-web'"
                    :field-group_class="'field-group_view_compact field-group_view_table field-group__icon-50'"
                    :items="list"
                    :sortBy="dropDownObject?.sortBy || sortBy || ''"
                    :filterable="filterable"
                    v-model="selected"
                    @update:modelValue="(value) => setFilter(value)"
                />
                <button class="button button-no-style" @click="clearFilterField(object.field)" v-if="showClearFilter">
                    <span role="presentation" class="k-icon k-button-icon k-i-filter-clear"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableFilterSelect",
    props:{
        'object': Object,
        'filterable': Boolean,
        'clearFilterField': Function,
        'field': String,
        'operator': {
            type: String,
            default: () => 'eq'
        },
        'sortBy': String,
        'dropDownObject': {
            type: [Array, Object],
            default: () => { return [] }
        },
        'showClearFilter': {
            type: Boolean,
            default : () => false
        },
    },
    data(){
        return {
            selected: ''
        }
    },
    computed: {
        list() {
            let list = this.dropDownObject
            if(!Array.isArray(list)) list = list?.list
            return list
        },
    },
    methods:{
        setFilter(value) {
            let operator = this.list?.find(i => i.id == value)?.operator || this.operator
            this.object.onChange({
                operator: operator,
                field: this.object.field,
                value: (typeof value !== 'undefined') ? value : undefined,
            });
        }
    }
}
</script>

<style scoped>

</style>