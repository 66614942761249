import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
// import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

export default {
    state: {
        customizesFullLoad: false,
        customizes: [],
    },
    actions: {
        async fetchCustomizes/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if(!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchCustomizes', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('customizes', args)

                this.$api.customize.getAll({...params, ...with_removed})
                    .then((response) => {
                        if(response.status < 400 && !response.data.error){
                            if(!fullList) commit('updateCustomizes', response.data)
                            else commit('updateCustomizesFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    })
            })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchCustomizes', inprogress: false})
                });
        },
        async fetchCustomizesAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchCustomizesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchCustomizesChanged', time: Date.now() / 1000})
            dispatch('fetchCustomizes')
                .then((resp) => {
                    if (resp) {
                        let fetch = [];
                        // let count = getters.getUsers.length
                        // let pages = Math.ceil(count/consts.querySettings.pageSize)
                        // let fetch = range(pages).map(i => {
                        //     let page = i+1;
                        //     return new Promise((resolve) =>
                        //         setTimeout(async () => resolve(await dispatch('fetchCustomizes', {page})), page*100)
                        //     )
                        // });
                        Promise.all(fetch)
                            .then(() => {
                                commit('setCustomizesFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchCustomizesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchCustomizesChanged', inprogress: false})
                });
        },
        async fetchCustomizesChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchCustomizesChanged', time: Date.now() / 1000})
            dispatch('fetchCustomizes', args)
                .then(() => {
                    dispatch('fetchCustomizes', {fields: 'id_user',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchCustomizesChanged', inprogress: false})
                });
        },

        async fetchUserCustomize({ commit, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id_user) {
                    return reject(false)
                }
                const params = getApiProps('customizes', args)
                this.$api.customize.find(args.id_user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateCustomize', response.data)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        async updateCustomize({ commit, dispatch }, customize) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('customizes')
                this.$api.customize.update(customize.id_user, customize, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateCustomize', response.data)
                            dispatch('fetchCustomizesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setCustomizesFullLoad(state, FullLoad) {
            state.customizesFullLoad = state.customizesFullLoad || FullLoad
        },
        updateCustomizesFullList(state, nCustomizes) {
            if(!state.customizes.length) {
                state.customizes = nCustomizes.map(u => Object.freeze(u))
                return true
            }

            // console.log('updateCustomizes', state.customizes.length)
            nCustomizes.forEach(function (nCustomize) {
                let i = state.customizes.findIndex(u => (u.id_user == nCustomize.id_user))
                if(i<0) {
                    state.customizes.push(Object.freeze(nCustomize))
                }else
                if(!state.customizesFullLoad || state.customizes[i][changedField] != nCustomize[changedField]) {
                    state.customizes[i] = Object.freeze({...state.customizes[i], ...nCustomize})
                }
            })

            // let Ids = state.units.map(u=> u.id_user)
            let nIds = nCustomizes.map(u => u.id_user)
            let removedIds = state.customizes.filter(u => !nIds.includes(u.id_user)).map(u => u.id_user)
            removedIds.forEach(removedId => {
                let i = state.customizes.findIndex(u => (u.id_user == removedId))
                if(i > 0) {
                    state.customizes.splice(i, 1)
                }
            })
        },
        updateCustomizes(state, nCustomizes) {
            if(!state.customizes.length) {
                state.customizes = nCustomizes.map(u => Object.freeze(u))
                return true
            }

            // console.log('updateCustomizes', state.customizes.length)
            nCustomizes.forEach(function (nCustomize) {
                let i = state.customizes.findIndex(u => (u.id_user == nCustomize.id_user))
                if(i<0) {
                    state.customizes.push(Object.freeze(nCustomize))
                }else
                if(!state.customizesFullLoad || state.customizes[i][changedField] != nCustomize[changedField]) {
                    state.customizes[i] = Object.freeze({...state.customizes[i], ...nCustomize})
                }
            })
        },
        updateCustomize(state, nCustomize) {
            let i = state.customizes.findIndex(u => (u.id_user == nCustomize.id_user))
            if(i<0) {
                state.customizes.push(Object.freeze(nCustomize))
            }else
            if(!state.customizesFullLoad || state.customizes[i][changedField] != nCustomize[changedField]) {
                state.customizes[i] = Object.freeze({...state.customizes[i], ...nCustomize})
            }
        },
        deleteCustomize(state, id_user) {
            let i = state.customizes.findIndex(u => (u.id_user == id_user))
            if(i > 0) {
                state.customizes.splice(i, 1)
            }
        },
        clearCustomizes(state) {
            state.customizes = []
            state.customizesFullLoad = false
        },
    },
    getters: {
        isUsersCustomizesFullLoad(state) {
            return state.customizesFullLoad
        },
        getUsersCustomizes(state){
            return state.customizes
        },
        getCustomizesByUsersIds(state){
            return state.customizes.reduce((customizesByIds, c) => {
                customizesByIds[c.id_user] = c
                return customizesByIds
            }, {})
        },
    }
}
