// import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

import {arrayFilter_OnlyUnique} from "@/lib/lib";
import i18n from "@/i18n";

export default {

    state: {
        stops_index: [],
        stops: [],
    },
    actions: {
        async fetchStops4Date({ commit, getters }, args) {
            args = {unitId: '', date: '', ...args}
            if(args.date == 'today') args.date = getters.getDateToday
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            let index = getters.getStopsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
            if(index){
                return
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.stops.get4Date(args.unitId, args.date, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeStops', response.data)
                        commit('setStopsIndex', {...args, count: response.data.length})
                        if (!args.unitId && response.data.length) {
                            response.data
                                .map(e => e.unit_id)
                                .filter(arrayFilter_OnlyUnique)
                                .forEach(unitId => {
                                    commit('setStopsIndex', {...args, unitId, count: 1})
                                })
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchStops4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let stop = getters.getStops.find(s => (s.unit_id == args.unitId && s.time?.utc >= args.from && s.time?.utc <= args.to))
            if(stop){
                return
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.stops.get4Period(args.unitId, args.from, args.to, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeStops', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setStopsIndex(state, params) {
            let i = state.stops_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if(i < 0) {
                state.stops_index.push(Object.freeze({...params}))
            } else {
                state.stops_index[i] = Object.freeze({...state.stops_index[i], ...params})
            }
        },
        storeStops(state, nStops) {
            nStops = nStops
                .filter(s => !state.stops.find(_s => {
                    return _s.unit_id == s.unit_id
                        && _s.time_start?.utc == s.time_start?.utc
                        && _s.time_end?.utc == s.time_end?.utc
                }))
                .map(s => Object.freeze(s))

            // if(nStops.length) state.stops.push(...nStops)
            if(!nStops.length) return false
            if(!state.stops.length){
                state.stops = nStops
                return true
            }
            state.stops.push(...nStops)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nStops)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.stops.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearStops(state){
            state.stops = [];
            state.stops_index = [];
        },
    },
    getters: {
        getStopsIndex(state){
            return state.stops_index
        },
        getStops(state){
            return state.stops
        },
        getStopsI18nMessages(state){
            return state.stops
                .map((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'events' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: event.type, xid: event.xid, message};
                })
        },
        getStopsI18nMessagesByXid(state){
            return state.stops
                .reduce((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'events' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    eventsTextByXid[event.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return eventsTextByXid;
                }, {})
        },
        getStopsByIds(state){
            return state.stops.reduce((stopsByIds, stop) => {
                stopsByIds[stop.id] = stop
                return stopsByIds
            }, {})
        },
    }
}
