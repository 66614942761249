import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {

    state: {
        parkings_index: [],
        parkings: [],
    },
    actions: {
        async fetchParkings4Date({ commit, getters }, args) {
            args = {unitId: '', date: '', ...args}
            if(args.date == 'today') args.date = getters.getDateToday
            let isToday = (args.date == getters.getDateToday)
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            // if today come throw 00h - it doesn't work (index ???)
            // let parking = getters.getParkings.find(t => (t.unit_id == args.unitId && t.date == args.date))
            // let record = getters.getParkingsIndex.find(i => i.date == args.date &&  i.unit_id == args.unitId  && (!i.timeout || i.timeout > (Date.now() / 1000)))
            // if(parking && args.date !== getters.getDateToday) {
            //     return
            // }
            let params = {withAddress: 1, ...args.params}
            this.$api.parkings.get4Date(args.unitId, args.date, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeParkings', response.data)
                        commit('setParkingsIndex', {
                            ...args,
                            count: response.data.length,
                            timeout: isToday ? ((Date.now() / 1000) + consts.indexTimeout.parkings) : false
                        })
                        if (!args.unitId && response.data.length) {
                            // debugger
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchParkings4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let parking = getters.getParkings.find(t => (t.unit_id == args.unitId && t.time_start?.utc==args.from && t.time_end?.utc==args.to))
            if(parking){
                return
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.parkings.get4Period(args.unitId, args.from, args.to, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeParkings', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        removeParkingsIndex(state) {
            state.parkings_index =
                state.parkings_index.filter(i => !i.timeout || i.timeout > (Date.now() / 1000))
        },
        setParkingsIndex(state, params) {
            let i = state.parkings_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if(i < 0) {
                state.parkings_index.push(Object.freeze({...params}))
            } else {
                state.parkings_index[i] = Object.freeze({...state.parkings_index[i], ...params})
            }
        },
        storeParkings(state, nParkings) {
            nParkings = nParkings
                .filter(p => !state.parkings.find(_p => {
                    return _p.unit_id == p.unit_id
                        && _p.time_start?.utc == p.time_start?.utc
                        // && _p.time_end?.utc == p.time_end?.utc
                }))
                .map(p => Object.freeze(p))

            // if(nParkings.length) state.parkings.push(...nParkings)
            if(!nParkings.length) return false
            if(!state.parkings.length){
                state.parkings = nParkings
                return true
            }
            state.parkings.push(...nParkings)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nParkings)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.parkings.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearParkings(state){
            state.parkings = [];
            state.parkings_index = [];
        },
    },
    getters: {
        getParkingsIndex(state){
            return state.parkings_index
        },
        getParkings(state){
            return state.parkings
        },
        getParkingsByIds(state){
            return state.parkings.reduce((parkingsByIds, parking) => {
                parkingsByIds[parking.id] = parking
                return parkingsByIds
            }, {})
        },
    }
}
