// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}

export default {
    state: {
        unitsLmsgsFullLoad: false,
        unitsLmsgs: [],
        unitsLmsgsTimeAgo: [],
    },
    actions: {
        async fetchUnitsLmsgs({ commit, dispatch, getters }, args={withAddress: 1}) {
            //if(args.force) console.log('fetchUnitsLmsgs', args.force, Date.now() / 1000)
            if(!getters.apiToken) {
                return false
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchUnitsLmsgs', time: Date.now() / 1000})
            let params = {withAddress: !!args.withAddress}
            if(!args.force) params.lmsgtimes = getters.unitsLmsgsTimeByIds
            this.$api.units.getLmsgs(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        if (response.data && response.data.length) {
                            commit('updateUnitsLmsgs', response.data)
                            if(!getters.isUnitsLmsgsFullLoad && !args?.lite) {
                                commit('setUnitsLmsgsFullLoad', !args?.lite)
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchUnitsLmsgs', inprogress: false})
                });

            /*if(!args.force)
            {
                setTimeout(function () {
                    dispatch('fetchUnitsLmsgs', {});
                }, 10 * 1000)
            }*/
        },

        async fetchUnitLmsg({ commit, dispatch, getters }, args) {
            if(!getters.apiToken || !args.id) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchUnitLmsg', time: Date.now() / 1000})
            const params = {lmsgtime: getters.unitsLmsgsTimeByIds[args.id], withAddress: 1, ...args}
            this.$api.units.getLmsg(args.id, params)
                .then((response) => {
                    if(response.status < 400 && response.data) {
                        commit('updateUnitsLmsg', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitLmsg', inprogress: false})
                });
        },

        async updateUnitCounters({ commit },args) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.updateCounters(args.id, args.counters, {...params, ...with_removed})
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            // console.log(response.data)
                            commit('updateUnitsLmsg', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async incrementUnitsLmsgTimeAgo({ commit }){
            commit('incrementUnitsLmsgTimeAgo')
        },

        //sayHello() {}
    },
    mutations: {
        setUnitsLmsgsFullLoad(state, FullLoad) {
            state.unitsLmsgsFullLoad = state.unitsLmsgsFullLoad || FullLoad
        },
        updateUnitsLmsgs(state, nLmsgs) {
            if(!state.unitsLmsgs.length){
                state.unitsLmsgs = nLmsgs.map(l => Object.freeze(l))
                //Lmsgs TimeAgo
                state.unitsLmsgsTimeAgo = nLmsgs.map(nLmsg => {
                    return {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
                })
                return true
            }
            // console.log('updateLmsgs', state.unitsLmsgs.length)
            nLmsgs.forEach(function (nLmsg) {
                let i = state.unitsLmsgs.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
                let lmsg = state.unitsLmsgs[i]
                if(!lmsg) {
                    state.unitsLmsgs.push(Object.freeze(nLmsg))
                }else
                if(lmsg.time < nLmsg.time || !(lmsg.address>' ')){
                    state.unitsLmsgs[i] = Object.freeze({...lmsg, ...nLmsg})
                }
                //Lmsgs TimeAgo
                nLmsg = {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
                let ii = state.unitsLmsgsTimeAgo.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
                if(ii < 0){
                    state.unitsLmsgsTimeAgo.push(nLmsg)
                } else {
                    state.unitsLmsgsTimeAgo[ii] = {...state.unitsLmsgsTimeAgo[ii], ...nLmsg}
                }
            })
        },
        updateUnitsLmsg(state, nLmsg) {
            let i = state.unitsLmsgs.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            let lmsg = state.unitsLmsgs[i]
            if(!lmsg) {
                state.unitsLmsgs.push(Object.freeze(nLmsg))
            }else
            if(lmsg.time < nLmsg.time || !(lmsg.address>' ')){
                state.unitsLmsgs[i] = Object.freeze({...lmsg, ...nLmsg})
            }
            //Lmsgs TimeAgo
            nLmsg = {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo}
            let ii = state.unitsLmsgsTimeAgo.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            if(ii < 0){
                state.unitsLmsgsTimeAgo.push(nLmsg)
            } else {
                state.unitsLmsgsTimeAgo[ii] = {...state.unitsLmsgsTimeAgo[ii], ...nLmsg}
            }
        },
        updateUnitLmsgCounters(state, nLmsg) {
            let i = state.unitsLmsgs.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            let lmsg = state.unitsLmsgs[i]
            if(!lmsg) {
                state.unitsLmsgs.push(Object.freeze(nLmsg))
            }else
            if(lmsg.time < nLmsg.time || !(lmsg.address>' ')){
                state.unitsLmsgs[i] = Object.freeze({...lmsg, ...nLmsg})
            }
            //Lmsgs TimeAgo
            nLmsg = {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
            let ii = state.unitsLmsgsTimeAgo.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            if(ii < 0){
                state.unitsLmsgsTimeAgo.push(nLmsg)
            } else {
                state.unitsLmsgsTimeAgo[ii] = {...state.unitsLmsgsTimeAgo[ii], ...nLmsg}
            }
        },

        clearUnitsLmsgsSpeed(state){
            state.unitsLmsgs = state.unitsLmsgs.map(lmsg => { return {...lmsg, time: 0, speed: null, timeAgo: null, isActive: null}})
        },
        clearUnitsLmsgs(state) {
            state.unitsLmsgs = []
            state.unitsLmsgsFullLoad = false
        },

        incrementUnitsLmsgTimeAgo(state){
            state.unitsLmsgsTimeAgo.forEach(lmsg => {
                if(typeof lmsg.timeAgo === 'number') {
                    lmsg.timeAgo++
                    lmsg.isActive = (lmsg.timeAgo < consts.status.offline)
                }
            })
        },
    },
    getters: {
        isUnitsLmsgsFullLoad(state) {
            return state.unitsLmsgsFullLoad
        },
        unitsLmsgs(state){
            return state.unitsLmsgs
        },
        unitsIdsIsMoving(state){
            return state.unitsLmsgs
                .filter(lmsg => lmsg?.status?.isMoving === true)
                .map(lmsg => lmsg.object_id)
        },
        unitsIdsIsParked(state){
            return state.unitsLmsgs
                .filter(lmsg => lmsg?.status?.isParked === true)
                .map(lmsg => lmsg.object_id)
        },
        unitsIdsIsIgnitionOn(state){
            return state.unitsLmsgs
                .filter(lmsg => !!lmsg?.status?.ignition?.value)
                .map(lmsg => lmsg.object_id)
        },
        unitsLmsgsTimeAgo(state){
            return state.unitsLmsgsTimeAgo
        },
        unitsIdsIsActive(state){
            return state.unitsLmsgsTimeAgo
                .filter(lmsg => lmsg.isActive === true)
                .map(lmsg => lmsg.object_id)
        },
        unitsIdsIsNotActive(state){
            return state.unitsLmsgsTimeAgo
                .filter(lmsg => lmsg.isActive === false)
                .map(lmsg => lmsg.object_id)
        },
        unitsLmsgsIds(state){
            return state.unitsLmsgs.map(lmsg => lmsg.object_id)
        },
        unitsLmsgsTimeByIds(state){
            return state.unitsLmsgs.reduce((timeByLmsgIds, lmsg) => {
                if(lmsg.time)
                    timeByLmsgIds[lmsg.object_id] = lmsg.time
                return timeByLmsgIds
            }, {})
        },
        unitsLmsgsLatlngByIds(state){
            return state.unitsLmsgs.reduce((latlngBylmsgIds, lmsg) => {
                if(lmsg.latlng) latlngBylmsgIds[lmsg.object_id] = lmsg.latlng
                return latlngBylmsgIds
            }, {})
        },
        unitsLmsgsAddressByIds(state){
            return state.unitsLmsgs.reduce((addressBylmsgIds, lmsg) => {
                let address = lmsg.address
                if(!lmsg?.address && lmsg?.latlng){
                    address = `lat: ${lmsg.latlng.lat}, lon: ${lmsg.latlng.lng}`
                    //lmsg = {...lmsg, address}
                }
                addressBylmsgIds[lmsg.object_id] = address
                return addressBylmsgIds
            }, {})
        },
        unitsLmsgsTimeAgoByIds(state){
            return state.unitsLmsgsTimeAgo.reduce((timeAgoByLmsgIds, lmsg) => {
                if(lmsg.time) timeAgoByLmsgIds[lmsg.object_id] = lmsg.timeAgo
                return timeAgoByLmsgIds
            }, {})
        },
        unitsLmsgsIsActiveByIds(state){
            return state.unitsLmsgsTimeAgo.reduce((lmsgIsActiveByIds, lmsg) => {
                if(lmsg.time) lmsgIsActiveByIds[lmsg.object_id] = lmsg.isActive
                return lmsgIsActiveByIds
            }, {})
        },
        unitsLmsgsByIds(state){
            return state.unitsLmsgs.reduce((lmsgsByIds, lmsg) => {
                if(!lmsg?.address && lmsg?.latlng){
                    let address = `lat: ${lmsg.latlng.lat}, lon: ${lmsg.latlng.lng}`
                    lmsg = {...lmsg, address}
                }
                lmsgsByIds[lmsg.object_id] = lmsg
                return lmsgsByIds
            }, {})
        },
    }
}
