<template>
    <div class="checkbox-control"
         :class="controlClass"
         @click.prevent.stop="setValue"
    >
        <label class="checkbox-control__label">
            <span class="checkbox-control__text">{{ label }}</span>
            <input class="checkbox-control__checkbox" type="checkbox" v-model="checkbox" :disabled="disabled">
            <span class="checkbox-control__switch"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "CheckboxControl",
    emits:['update:modelValue'],
    props: {
        'controlClass': {
            type: [String, Array, Object],
            default: () => ''
        },
        'label': String,
        'modelValue': [Number, Boolean],
        'disabled' : [Boolean],
        'isRadio': {
            type: Boolean,
            default: () => false
        },
    },
    data() {
        return {}
    },
    computed: {
        checkbox: {
            get(){ return !!this.modelValue },
            set(v){
                if(this.isRadio && !v) return false;
                this.$emit('update:modelValue', v)
            }
        }
    },
    methods:{
        setValue(){
            if(!this.disabled) this.checkbox = !this.checkbox
        }
    }
}
</script>

<style scoped>

</style>