let routerNames = {
    dashboard: {
        main: 'Dashboard',
    },
    dealer: {
        main: 'Dealer',
    },
    help: {
        main: 'Help',
    },
    app: {
        user: {
            edit: {
                main: 'App.user.edit'
            }
        }
    },
    users: {
        main: 'Users',
        actions: 'Users_actions',
        history: 'Users.history',
        edit: {
            main: 'Users.edit',
            password: 'Users.change-password',
            create: 'Users.create',
            setup_dns: 'Users.setup-dns',
            customize: 'Users.edit.customize',
        }
    },
    md: {
        main: 'MD',
        widget: "MD.widget"
    },
    units: {
        main: 'Units',
        id: 'Units.id',
        all: 'Units.all',
        lite: 'Units.lightView',
        locator: 'Units.locator',
        chat: 'Units.chat',
        history: 'Units.history',
        widget: 'Units.widget',
        commands: {
            main: 'Units.command',
            group: 'Units.groupCommand',
        },
        info: 'Units.info',
        fast_geozone: 'Units.fastGeozone',
        edit: {
            main: 'Units.edit',
            group: 'Units.groupEdit',
            create: 'Units.create',
        },
        video: {
            main: 'Units.video',
            live: 'Units.liveVideo',
            event: 'Units.video-event',
            request: 'Units.video-request',
        },
        type: {
            xtrack: {
                main: 'Units.xtrack',
                info: 'Units.xtrack.info',
                edit: 'Units.xtrack.edit',
                liveVideo: 'Units.xtrack.live-video',
                videoEvent: 'Units.xtrack.video-event',
                videoRequest: 'Units.xtrack.video-request',
                sensors: 'Units.xtrack.sensors',
            },
            xvision: {
                main: 'Units.xvision',
                info: 'Units.xvision.info',
                edit: 'Units.xvision.edit',
                liveVideo: 'Units.xvision.live-video',
                videoEvent: 'Units.xvision.video-event',
                videoRequest: 'Units.xvision.video-request',
                sensors: 'Units.xvision.sensors',
            },
            xpower: {
                main: 'Units.xpower',
                info: 'Units.xpower.info',
                edit: 'Units.xpower.edit',
                liveVideo: 'Units.xpower.live-video',
                videoEvent: 'Units.xpower.video-event',
                videoRequest: 'Units.xpower.video-request',
                sensors: 'Units.xpower.sensors',
            },
            xcool: {
                main: 'Units.xcool',
                info: 'Units.xcool.info',
                edit: 'Units.xcool.edit',
                liveVideo: 'Units.xcool.live-video',
                videoEvent: 'Units.xcool.video-event',
                videoRequest: 'Units.xcool.video-request',
                sensors: 'Units.xcool.sensors',
            },
            xcargo: {
                main: 'Units.xcargo',
                info: 'Units.xcargo.info',
                edit: 'Units.xcargo.edit',
                liveVideo: 'Units.xcargo.live-video',
                videoEvent: 'Units.xcargo.video-event',
                videoRequest: 'Units.xcargo.video-request',
                sensors: 'Units.xcargo.sensors',
            },
            xlink: {
                main: 'Units.xlink',
                info: 'Units.xlink.info',
                edit: 'Units.xlink.edit',
                liveVideo: 'Units.xlink.live-video',
                videoEvent: 'Units.xlink.video-event',
                videoRequest: 'Units.xlink.video-request',
                sensors: 'Units.xlink.sensors',
            },
        },
        track: {
            main: "Units.track",
            xid: "Units.track.xid",
            event_xid: "Units.track.event.xid"
        }
    },
    tracks: {
        main: 'Tracks',
        lite: 'Tracks.lightView',
        xid: 'Tracks.xid',
        event_xid: 'Tracks.event-xid',
        move_on_track: 'Tracks.moveOnTrack',
        video: {
            event: 'Tracks.video-event',
            request: 'Tracks.video-request',
        }
    },
    keys: {
        main: 'Keys',
        history: {
            key: 'Keys.history',
            keys: 'Keys.keys-history',
            order: 'Keys.order-history',
        },
        edit: {
            transfer: 'Keys.transfer',
            transfer_by_id: 'Keys.transferById',
            generate: 'Keys.generate',
        }
    },
    notificationsevents: {
        main: 'NotificationsEvents',
        lite: 'NotificationsEvents.lightView',
        edit: {
            main: 'NotificationsEvents.edit',
        },
        popup: {
            main: 'NotificationsEvents.popup',
        }
    },
    geoitems: {
        main: 'Geoitems',
        lite: 'Geoitems.lightView',
        edit: {
            main: 'Geoitems.edit',
        }
    },
    object_changes: {
        main: 'ObjectsChanges',
    },
    subscriptions: {
        main: 'Subscriptions',
        update_unit_licence: 'subscriptions.update-unit-licence',
        edit: {
            unit: 'subscriptions.unitEdit',
            user: 'subscriptions.userEdit',
        }
    },
    recycle_bin: {
        main: 'RecycleBin',
        edit: {
            unit: 'RecycleBin.unit-edit',
        },
        history: {
            unit: 'RecycleBin.unit-history',
            user: 'RecycleBin.user-history',
        }
    },
    statistics: {
        main: 'Statistics',
        statement: 'Statistics.statement',
    },
    routes: {
        main: 'Routes',
    },
    locator: {
        main: 'Locator',
    },
    alarms: {
        main: 'Alarms',
    },
    dashboard_gauges: {
        main: 'DashboardGauges',
    },
    fuel: {
        main: 'Fuel',
    },
    fuel_cards: {
        main: 'FuelCards',
    },
    maintenance: {
        main: 'Maintenance',
    },
    personnel: {
        main: 'Personnel',
    },
    reports: {
        main: 'Reports',
        id: 'Reports.id',
        template: 'Reports.template',
    },
    video: {
        main: 'Video',
    },
    passengers: {
        main: '',//toDo route name
    }
}

const consts = {
    querySettings: {
        filter: {'filter[changed]': -600},
        pageSize: 100,
        // unitsPageSize: 100,
    },
    routerNames,
    templates: {
        unitLite: {
            routeName: routerNames.units.lite,
            extensions: {
                byUnitHwType: ['xpower'],// xlink - ???
            },
        },
        // tracksLite: {
        //     routeName: routerNames.tracks.lite,
        //     extensions: {},
        // },
        // geoitemsLite: {
        //     routeName: routerNames.geoitems.lite,
        //     extensions: {},
        // },
        // notificationseventsLite: {
        //     routeName: routerNames.notificationsevents.lite,
        //     extensions: {},
        // }
    },
    shortWidth: 690,
    map: {
        center: [25.761681, -80.191788],
    },
    dealer_shop: 'https://83fbbd-2.myshopify.com/',
    shopify: {
        dealer: {
            domain: '83fbbd-2.myshopify.com',
            storefrontAccessToken: '97f09cc3f6cdb53725424cffe62d69ff'
        },
        client: {
            domain: '83fbbd-2.myshopify.com',
            storefrontAccessToken: '97f09cc3f6cdb53725424cffe62d69ff'
        },
    },
    indexTimeout: {
        parkings: 5 * 60,
        trips: 5 * 60,
        videoRecords: 5 * 60,
    },
    units_of_measure: ['imperial', 'metric'],
    recyclebin_time_left: 60 * 24 * 3600,
    menus: {
        portal: [
            {id: "md",              name: routerNames.md?.main,          title: ("MD"), icon: "menu__md"/*icon: "menu__car"*/},
            {id: "units",           name: routerNames.units?.main,          title: ("menu.Units"), icon: "menu__car"/*icon: "menu__car"*/},
            {id: "reports",         name: routerNames.reports?.main,        title: ("menu.Reports"), icon: "menu__reports"},
            // {id: "tracks",          name: ''/*name: routerNames.tracks.main*/,         title: ("menu.Tracks"), icon: "menu__tracks"},
            // {id: "geoitems",        name: ''/*name: routerNames.geoitems.main*/,       title: ("menu.Geoitems"), icon: "menu__geoitems"},
            // {id: "notifications",   name: ''/*name: routerNames.notificationsevents.main*/,  title: ("menu.Notice"), icon: "menu__notifications"},
            // {id: "routes",          name: ''/*name: routerNames.routes.main*/,         title: ("menu.Routes"), icon: "menu__routes"},
            // {id: "personnel",       name: ''/*name: routerNames.personnel.main*/,      title: ("menu.Personnel"), icon: "menu__personnel"},
            // {id: "passengers",      name: ''/*name: routerNames.passengers.main*/,     title: ("menu.Passengers"), icon: "menu__passengers"},
            // {id: "fuel",            name: ''/*name: routerNames.fuel.main*/,           title: ("menu.Fuel"), icon: "menu__fuel"},
            // {id: "maintenance",     name: ''/*name: routerNames.maintenance.main*/,    title: ("menu.Maintenance"), icon: "menu__maintenance"},
            // {id: "alarms",          name: ''/*name: routerNames.alarms.main*/,         title: ("menu.Alarms"), icon: "menu__alarm"},
            // {id: "video",           name: ''/*name: routerNames.video.main*/,          title: ("menu.Video"), icon: "menu__video"},
            // {id: "fuel_cards",      name: ''/*name: routerNames.fuel_cards.main*/,     title: ("menu.Fuel cards"), icon: "menu__fuel_cards"},
            // {id: "gauges",          name: ''/*name: routerNames.dashboard_gauges.main*/, title: ("menu.Gauges"), icon: "menu__gauges"},
            //
            // {id: "help",            name: ''/*name: routerNames.help.main*/,           title: ('menu.Help'), icon: "menu__help"},
            // {id: "test",         name: routerNames.test.main,           title: ('menu.TestPage'), icon: "menu__test"},
        ],
        admin: [
            // {id: "statistics", name: routerNames.statistics.main, title: ('menu.Dashboard'), icon: "menu__dashboard"},
            {id: "users", name: routerNames.users.main, title: ("menu.Users"), icon: "menu__personnel"},
            {id: "units", name: routerNames.units.main, title: ("menu.Units"), icon: "menu__car"},
            // {id: "keys", name: routerNames.keys.main, title: ("menu.Keys"), icon: "menu__keys"},
            // {id: "subscriptions", name: routerNames.subscriptions.main, title: ('menu.Subscriptions'), icon: "menu__dealer"},
            {id: "recycle-bin", name: routerNames.recycle_bin.main, title: ("menu.Recycle Bin"), icon: "menu__delete"},
            // {id: "user-actions", name: routerNames.users.actions.main, title: ("menu.User actions"), icon: "menu__history"},
            // {id: "objs-changes", name: routerNames.object_changes.main, title: ("menu.Objs changes"), icon: "menu__history"},
            // {id: "dealer",          name: routerNames.dealer.main,         title: ('menu.Dealer'), icon: "menu__dealer"},
            // {id: "help",            name: routerNames.help.main,           title: ('menu.Help'), icon: "menu__help"},
            // {id: "test",            name: routerNames.test.main,           title: ('menu.TestPage'), icon: "menu__test"},
        ],
        mainSize: 7,
    },
    FilterSearchLists: {
        Units: {
            signals: [
                {id: 'on', name: ('ON line'), translate: ('Units.signals.ON line'), icon: 'common__car'},
                {id: 'off', name: ('OFF line'), translate: ('Units.signals.OFF line'), icon: 'common__car'},
            ],
            movements: [
                {id: 'moving', name: ('Moving'), translate: ('Units.movements.Moving'), icon: 'common__car'},
                {id: 'stopped', name: ('Stopped'), translate: ('Units.movements.Stopped'), icon: 'common__car'},
                {id: 'ignition', name: ('Ignition on'), translate: ('Units.movements.Ignition on'), icon: 'common__car'},
            ],
            car_icons: [
                {id: 'map__car_type_sedan', name: ('Sedan'), translate: ('Units.icons.Sedan'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_pickup', name: ('Pickup'), translate: ('Units.icons.Pickup'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_truck', name: ('Truck'), translate: ('Units.icons.Truck'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_motorbike', name: ('Motorbike'), translate: ('Units.icons.Motorbike'), group:'Dynamic', isColored: true},
                {id: 'map__car_type_taxi', name: ('Taxi'), translate: ('Units.icons.Taxi'), group:'Static', isColored: false},
                {id: 'map__car_type_police', name: ('Police'), translate: ('Units.icons.Police'), group:'Static', isColored: false},
                {id: 'map__car_type_ambulance', name: ('Ambulance'), translate: ('Units.icons.Ambulance'), group:'Static', isColored: false},
            ],
            types: [
                {id: 'complex', name: ('Complex'), translate: ('Units.types.Complex'), icon: 'complex'},
                {id: 'boat', name: ('Boat'), translate: ('Units.types.Boat'), icon: 'boat'},
                {id: 'bus', name: ('Bus'), translate: ('Units.types.Bus'), icon: 'bus'},
                {id: 'car', name: ('Car'), translate: ('Units.types.Car'), icon: 'car'},
                {id: 'dry trailer', name: ('Dry Trailer'), translate: ('Units.types.Dry Trailer'), icon: 'dry_trailer'},
                {id: 'engine', name: ('Engine'), translate: ('Units.types.Engine'), icon: 'engine'},
                {id: 'fuel tank', name: ('Fuel Tank'), translate: ('Units.types.Fuel Tank'), icon: 'fuel_tank'},
                {id: 'fuel tanker', name: ('Fuel Tanker'), translate: ('Units.types.Fuel Tanker'), icon: 'fuel_tanker'},
                {id: 'generator', name: ('Generator'), translate: ('Units.types.Generator'), icon: 'generator'},
                {id: 'jet ski', name: ('Jet Ski'), translate: ('Units.types.Jet Ski'), icon: 'jet_ski'},
                {id: 'motorcycle', name: ('Motorcycle'), translate: ('Units.types.Motorcycle'), icon: 'motorcycle'},
                {id: 'plant machine', name: ('Plant Machine'), translate: ('Units.types.Plant Machine'), icon: 'plant_machine'},
                {id: 'pump', name: ('Pump'), translate: ('Units.types.Pump'), icon: 'pump'},
                {id: 'rv', name: ('RV'), translate: ('Units.types.RV'), icon: 'rv'},
                {id: 'reefer gt', name: ('Reefer GT'), translate: ('Units.types.Reefer GT'), icon: 'reefer_gt'},
                {id: 'reefer trailer', name: ('Reefer Trailer'), translate: ('Units.types.Reefer Trailer'), icon: 'reefer_trailer'},
                {id: 'stationary unit', name: ('Stationary Unit'), translate: ('Units.types.Stationary Unit'), icon: 'stationary_unit'},
                {id: 'tank', name: ('Tank'), translate: ('Units.types.Tank'), icon: 'tank'},
                {id: 'tractor', name: ('Tractor'), translate: ('Units.types.Tractor'), icon: 'tractor'},
                {id: 'train', name: ('Train'), translate: ('Units.types.Train'), icon: 'train'},
                {id: 'truck', name: ('Truck'), translate: ('Units.types.Truck'), icon: 'truck'},
                {id: 'van', name: ('Van'), translate: ('Units.types.Van'), icon: 'van'},
                {
                    id: 'water tanker',
                    name: ('Water Tanker'),
                    translate: ('Units.types.Water Tanker'),
                    icon: 'water_tanker'
                },
            ],
        },
        Events: {
            types: [
                {id: 'lost connection', type: 'event', name: ('Lost connection'), translate: ('Events.types.Lost connection'), icon: 'notifications__lost-connection'},
                {id: 'low battery', type: 'event', name: ('Low battery'), translate: ('Events.types.Low battery'), icon: 'notifications__low-battery'},
                {id: 'max speed', type: 'event', name: ('Max speed'), translate: ('Events.types.Max speed'), icon: 'notifications__max-speed'},
                {id: 'input', type: 'event', name: ('Input alert'), translate: ('Events.types.Input alert'), icon: 'notifications__input'},
            ],
        },
        NotificationsEvents: {
            types: [
                // {id: '1', type: 'notification-event', name: ('Parameters control'), translate: ('NotifEvents.types.Parameters control'), icon: 'notifications__parameter_control'},
                {id: '2', type: 'notification-event', name: ('Entry into geosite'), translate: ('NotifEvents.types.Entry into geosite'), icon: 'notifications__geozone_entrance_control'},
                {id: '3', type: 'notification-event', name: ('Exit from geosite'), translate: ('NotifEvents.types.Exit from geosite'), icon: 'notifications__geozone_exit_control'},
                // {id: '4', type: 'notification-event', name: ('Approaching geopoint'), translate: ('NotifEvents.types.Approaching geopoint'), icon: 'notifications__approaching_geopoint'},
                // {id: '5', type: 'notification-event', name: ('Distance geopoint'), translate: ('NotifEvents.types.Distance geopoint'), icon: 'notifications__distance_geopoint'},
                // {id: '6', type: 'notification-event', name: ('Lost connection'), translate: ('NotifEvents.types.Lost connection'), icon: 'notifications__lost-connection'},
                // {id: '7', type: 'notification-event', name: ('Sensors control'), translate: ('NotifEvents.types.Sensors control'), icon: 'notifications__sensor_control'},
                // {id: '9', type: 'notification-event', name: ('Approaching object'), translate: ('NotifEvents.types.Approaching object'), icon: 'notifications__approaching_object'},
                // {id: '10', type: 'notification-event', name: ('Overspeeding by GIS'), translate: ('NotifEvents.types.Overspeeding by GIS'), icon: 'notifications__overspeeding_gis'},
                // {id: '11', type: 'notification-event', name: ('Maintenance'), translate: ('NotifEvents.types.Maintenance'), icon: 'notifications__maintenance'},
                // {id: '12', type: 'notification-event', name: ('Fuel control'), translate: ('NotifEvents.types.Fuel control'), icon: 'notifications__fuel_control'},
                // {id: '17', type: 'notification-event', name: ('Towing'), translate: ('NotifEvents.types.Towing'), icon: 'notifications__towing'},

                //{id: '8', type: 'notification-event', name: ('Track control'), translate: ('NotifEvents.types.Track control'), icon: 'notifications__'},
                //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__condition_control'},//???
                //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__movement'},
                //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__parking'},
                //'attribute' => 'type_condition', 'images' => ['movement' => 'movement','parking' => 'parking',],

                //{id: '15', type: 'notification-event', name: ('Control of presence inside of geozone'), translate: ('NotifEvents.types.Control of presence inside of geozone'), icon: 'notifications__'},
                //{id: '16', type: 'notification-event', name: ('Control of absence inside of geozone'), translate: ('NotifEvents.types.Control of absence inside of geozone'), icon: 'notifications__'},

            ],
        },
        VideoEvents: {
            types: [
                {id: 'video-event', type: 'video-event', name: ('Video event'), translate: ('VideoEvents.types.Video event'), icon: 'notifications__video-event'},
                // {id: 'ADAS', type: 'video-event', name: ('ADAS Video event'), translate: ('VideoEvents.types.ADAS Video event'), icon: 'notifications__video-event'},
                // {id: 'other', type: 'video-event', name: ('Other Video event'), translate: ('VideoEvents.types.other Video event'), icon: 'notifications__video-event'},
            ],
        }
    },
    ReeferTypes: [
        {
            'title': '-',
            'type': '',
        },
        {
            'title': 'Thermo King',
            'type': 'thermo_king',
            'icon': 'reefer__king',
        },
        {
            'title': 'Carrier',
            'type': 'carrier',
            'icon': 'reefer__carrier',
        },
    ],
    unitTypesByHwType: {
        default: [
            {
                'title': 'Camera',
                'value':  'camera',
            },
            {
                'title': 'Trailer',
                'value':  'trailer',
            },
            {
                'title': 'Vehicle',
                'value':  'vehicle',
            },
            {
                'title': 'Asset',
                'value':  'asset',
            },

        ],
        xtag: [
            {
                'title': 'BLE Tag',
                'value':  'ble_tag',
            }
        ],
    },
    UnitTypes: [
        {value: "complex_unit", title: " Complex Unit"},
        {value: "boat", title: "Boat"},
        {value: "bus", title: "Bus"},
        {value: "auto", title: "Car"},
        {value: "dry_trailer", title: "Dry Trailer"},
        {value: "fuel_tank", title: "Fuel Tank"},
        {value: "fuel_tanker", title: "Fuel Tanker"},
        {value: "generator", title: "Generator"},
        {value: "jet_ski", title: "Jet Ski"},
        {value: "motorcycle", title: "Motorcycle"},
        {value: "oil_control", title: "Oil Control"},
        {value: "plant_machine", title: "Plant Machine"},
        {value: "pump", title: "Pump"},
        {value: "rv", title: "RV"},
        {value: "reefer_gt", title: "Reefer GT"},
        {value: "reefer_trailer", title: "Reefer Trailer"},
        {value: "router", title: "Router"},
        {value: "sensor", title: "Stationary unit"},
        {value: "tank", title: "Tank"},
        {value: "tractor", title: "Tractor"},
        {value: "train", title: "Train"},
        {value: "truck", title: "Truck"},
        {value: "van", title: "Van"},
        {value: "water_tanker", title: "Water Tanker"},
    ],
    cameraTypes:[
        {title:"Concox", value: 'concox'},
        {title:"Howen", value: 'howen'},
        {title:"Streamax", value: 'streamax'},
        {title:"Surfsight", value: 'surfsight'},
        {title:"SmartWitness", value: 'smart_witness'},
    ],
    cameraDeviceTypes: [
        { value: "Hero-ME30-04", title: "Hero-ME30-04"},
        { value: "Hero-ME31-04", title: "Hero-ME31-04"},
        { value: "Hero-ME31-08", title: "Hero-ME31-08"},
        { value: "Hero-ME32-04", title: "Hero-ME32-04"},
        { value: "Hero-ME34-04", title: "Hero-ME34-04"},
        { value: "Hero-ME34-08", title: "Hero-ME34-08"},
        { value: "Hero-ME35-04", title: "Hero-ME35-04"},
        { value: "Hero-ME35-08", title: "Hero-ME35-08"},
        { value: "Hero-ME40-02", title: "Hero-ME40-02"},
        { value: "Hero-ME40-04", title: "Hero-ME40-04"},
        { value: "Hero-ME41-02", title: "Hero-ME41-02"},
        { value: "Hero-ME41-04", title: "Hero-ME41-04"},
        { value: "Hero-MA80-08", title: "Hero-MA80-08"},
    ],
    costMode: [
        {value: 'units_all', title: 'Monthly fee for all objects'},
        {value: 'units_days_all', title: 'Daily fee for all units'}
    ],
    Licenses: {
        params: [
            {days: 30, text: '1 month', color: '#FE6D23'},
            {days: 90, text: '3 months', color: '#FFB700'},
            {days: 180, text: '6 months', color: '#90C20B'},
            {days: 365, text: '1 year', color: '#01CB25'},
        ],
    },
    uploadError: {
        "invalidFileExtension": "Invalid file extension",
        "invalidMaxFileSize": "Invalid max file size",
        "invalidMixFileSize": "Invalid min file size"
    },
    unitsList: {
        useTypeForTemplate: []
    },
}
export default consts