import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

export default {
    state: {
        usersFullLoad: false,
        users: [],
    },
    actions: {
        async fetchUsers/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if(!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUsers', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('users', args)

                this.$api.users.getAll({...params, ...with_removed})
                    .then((response) => {
                        if(response.status < 400 && !response.data.error){
                            if(!fullList) commit('updateUsers', response.data)
                            else commit('updateUsersFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchUsers', inprogress: false})
            });
        },

        async fetchUsersAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchUsersAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUsersChanged', time: Date.now() / 1000})
            dispatch('fetchUsers', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.getUsers.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchUsers', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setUsersFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchUsersAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUsersChanged', inprogress: false})
                });
        },

        async fetchUsersChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchUsersChanged', time: Date.now() / 1000})
            dispatch('fetchUsers', args)
                .then(() => {
                    dispatch('fetchUsers', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUsersChanged', inprogress: false})
                });
        },

        async fetchUser({ commit, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }
                const params = getApiProps('users', args)
                this.$api.users.find(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        async saveUser({ dispatch }, user) {
            let fn = (user.id) ? 'updateUser' : 'createUser'
            return dispatch(fn, user);
        },
        async createUser({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.create(user, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUser({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.update(user.id, user, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async deleteUser({ commit, dispatch }, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data)  commit('deleteUser', id)
                            else  commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async restoreUser({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.restore(id, {...params, ...with_removed})
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUserPermanently({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('users')
                this.$api.users.deletePermanently(id, {...params, ...with_removed})
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUser', id)
                            dispatch('fetchUsersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setUsersFullLoad(state, FullLoad) {
            state.usersFullLoad = state.usersFullLoad || FullLoad
        },
        updateUsersFullList(state, nUsers) {
            if(!state.users.length) {
                state.users = nUsers.map(u => Object.freeze(u))
                return true
            }

            // console.log('updateUsers', state.users.length)
            nUsers.forEach(function (nUser) {
                let i = state.users.findIndex(u => (u.id == nUser.id))
                if(i<0) {
                    state.users.push(Object.freeze(nUser))
                }else
                if(!state.usersFullLoad || state.users[i][changedField] != nUser[changedField]) {
                    state.users[i] = Object.freeze({...state.users[i], ...nUser})
                }
            })

            // let Ids = state.units.map(u=> u.id)
            let nIds = nUsers.map(u => u.id)
            let removedIds = state.users.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.users.findIndex(u => (u.id == removedId))
                if(i > 0) {
                    state.users.splice(i, 1)
                }
            })
        },
        updateUsers(state, nUsers) {
            if(!state.users.length) {
                state.users = nUsers.map(u => Object.freeze(u))
                return true
            }

            // console.log('updateUsers', state.users.length)
            nUsers.forEach(function (nUser) {
                let i = state.users.findIndex(u => (u.id == nUser.id))
                if(i<0) {
                    state.users.push(Object.freeze(nUser))
                }else
                if(!state.usersFullLoad || state.users[i][changedField] != nUser[changedField]) {
                    state.users[i] = Object.freeze({...state.users[i], ...nUser})
                }
            })
        },
        updateUser(state, nUser) {
            let i = state.users.findIndex(u => (u.id == nUser.id))
            if(i<0) {
                state.users.push(Object.freeze(nUser))
            }else
            if(!state.usersFullLoad || state.users[i][changedField] != nUser[changedField]) {
                state.users[i] = Object.freeze({...state.users[i], ...nUser})
            }
        },
        deleteUser(state, id) {
            let i = state.users.findIndex(u => (u.id == id))
            if(i > 0) {
                state.users.splice(i, 1)
            }
        },
        clearUsers(state) {
            state.users = []
            state.usersFullLoad = false
        },
    },
    getters: {
        isUsersFullLoad(state) {
            return state.usersFullLoad
        },
        getUsers(state){
            return state.users
        },
        getUsersByIds(state){
            return state.users.reduce((usersByIds, u) => {
                usersByIds[u.id] = u
                return usersByIds
            }, {})
        },
    }
}
