<template>
    <div class="aside-menu_wrap" :class="{'aside-menu_wrap__list': hasList}">
        <UserMenu class="menu__hamburger" v-if="!hasList && $consts.app.$isIntuit" :class="{'aside-menu_wrap-mobile':getMobileMenuOpen}"/>
        <aside class="section section_type_aside is_new-design" :class="{'aside-menu_wrap-mobile':getMobileMenuOpen}">
            <div class="menu">
                <div class="menu__hamburger" v-if="hasList && $consts.app.$isIntuit">
                    <button class="button button_view_icon button_theme_primary mtb__-4" @click="switchMainList">
                        <span class="icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__hamburger" /></span> </span>
                    </button>
                </div>
                <nav class="nav">
                    <ul class="nav__list">
                        <template v-for="route in main_menus" :key="route.id">
                            <li class="nav__item item"
                                :class="[(route?.name == getMainParentRouteName($route?.name)) && 'item_active router-link-exact-active' ]"
                                @click="onClickMainNavItem(route)"
                                @keypress.enter="onClickMainNavItem(route)"
                            >
                                <span v-if="route.badge && route.visible" class="nav__item__badge">{{ route.badge }}</span>
                                <div class="nav__icon-box">
                                    <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="route.icon" :title="$t(route.title)"/></span> </span>
                                    <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" :name="route.icon+'_fill'" :title="$t(route.title)"/></span> </span>
                                </div>
                                <span class="nav__title title">{{ route.title ? $t(route.title) : route.name }}</span>
                                <div class="nav__decor decor"></div>
                                <div class="nav__decor2 decor2"></div>
                            </li>
                        </template>

                        <template v-if="hasMoreMenu">
                            <!-- template -->
                                <!-- https://next.router.vuejs.org/api/#router-link-s-v-slot -->
                                <li class="nav__item item"
                                    :class="[(replaceable.name == getMainParentRouteName(route_name)) && 'item_active router-link-exact-active']"
                                    @click="onClickMainNavItem(replaceable)"
                                    @keypress.enter="onClickMainNavItem(replaceable)"
                                    v-if="replaceable"
                                >
                                    <div class="nav__icon-box" >
                                        <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="replaceable.icon" /></span> </span>
                                        <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" :name="replaceable.icon+'_fill'" /></span> </span>
                                    </div>
                                    <span class="nav__title title">{{replaceable.title ? $t(replaceable.title) : replaceable.name}}</span>
                                    <div class="nav__decor decor"></div>
                                </li>
                            <!-- /template -->

                            <li class="nav__item item item_type_more"
                                @mouseover="showItemTypeMore = true"
                                @mouseout="showItemTypeMore = false"
                            >
                                <div class="nav__icon-box">
                                    <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__more" /></span> </span>
                                    <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__more_fill" /></span> </span>
                                </div>
                                <span class="nav__title title">{{ $t('menu.More') }}</span>
                                <div class="nav__decor decor"></div>

                                <div class="nav__dropdown-menu dropdown-menu position_left-center" data-type=""
                                     :class="{'dropdown-menu_open': showItemTypeMore}"
                                >
                                    <div class="dropdown-menu__wrap">
                                        <ul class="dropdown-menu__list">
                                            <!-- template -->
                                                <li class="dropdown-menu__item item "
                                                    v-for="route in more_menus"
                                                    :key="route.id"
                                                    :class="[(route.name == getMainParentRouteName(route_name)) && 'item_active router-link-exact-active']"
                                                    @click="onClickDropdownNavItem(route)"
                                                    @keypress.enter="onClickDropdownNavItem(route)"
                                                    v-show="route.name !== replaceable.name"
                                                >
                                                    <span class="dropdown-menu__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="route.icon" /></span> </span>
                                                    <span class="dropdown-menu__item-name">{{route.title ? $t(route.title) : route.name}}</span>
                                                </li>
                                            <!-- /template -->
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </nav>
            </div>
            <div class="aside__info info">
                <SectionConnection />
                <SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getTimeFormat"/>
            </div>
        </aside>
    </div>

</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import SectionConnection from "@/components/_base/SectionConnection.vue";
    import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";
    import UserMenu from "@/components/app/UserMenu.vue";
    import {getMainParentRouteName} from "@/lib/lib";

    export default {
        name: "SectionAside",
        inject:['sectionGlobalClass'],
        props: [],
        data() {
            return {
                getMainParentRouteName,
                showItemTypeMore: false,
                mainSize: this.$consts.menus.mainSize,
                replaceable: null,
            }
        },
        components: {
            UserMenu,
            SectionCurrentDateTime,
            SectionConnection
        },
        computed: {
            ...mapGetters([
                "getAppUserId",
                "getAppMenus",
                "getMobileMenuOpen",
                "getUserTimeZone",
                "getTimeFormat",
                "getUserStorageKey",
                "getNewEventsCount",
                "getNewNotificationEventsCount",
                "getTimeToday",
                "getLastVisitNotifications",
            ]),
            isPrometheusAdmin() {
                return this.$product == this.$consts.productsName.prometheus && this.$site == this.$consts.siteName.admin
            },
            routeMeta() {
                return this.$route.meta
            },
            route_name(){
                return this.$route.name
            },
            mainRouteName() {
                return this.routeMeta.mainRouteName
            },
            routeFilter(){
                return this.$route.params
            },
            setRoute() {
                return this.routeMeta?.setRoute
            },
            hasMoreMenu(){
                return (this.getAppMenus.length > this.mainSize)
            },
            // mainSize(){
            //     // return this.$consts.menus.mainSize
            //     return this.$product == 'intuit' ? 8 : 5
            // },
            main_menus(){
                return this.getAppMenus.slice(0,this.mainSize).map(m => {
                    let extraOptionsMenuItem = this.extraOptionsMenu[m.id]
                    return {...m, ...extraOptionsMenuItem}
                })
            },
            more_menus(){
                return this.getAppMenus.slice(this.mainSize)
            },
            route_path(){
                return this.$route.path
            },
            menuIndex() {
                return this.main_menus.findIndex(m => m.id == this.$route.meta?.mainRouteName?.toLowerCase())
            },
            hasList(){
                return !!this.$route.meta.list
            },
            notifications(){
                let count = this.getNewEventsCount + this.getNewNotificationEventsCount
                let plus = (this.getTimeToday > this.getLastVisitNotifications ? '+' : '')
                return (count || plus) ? count + plus : false
            },
            extraOptionsMenu(){
                return {
                    'notificationsevents': {
                        badge: this.notifications,
                        visible: this.mainRouteName != this.$consts.routerNames.notificationsevents.main
                    }
                }
            }
        },
        watch: {
            menuIndex:{
                handler: function (index) {
                    let clss = (index === 0 ? 'first' : (index === this.main_menus.length - 1) ? 'last' : '')
                    this.sectionGlobalClass.class = clss ? 'manu-index-'+clss : ''
                }
            },
            // route_path: {
            //     handler: function (newVl, oldVl) {
            //         if(newVl !== oldVl){
            //             if(this.$isDevelopment) console.log('route_path', oldVl, '-->', newVl)
            //             this.setRoutePath();
            //         }
            //     }
            // },
            hasMoreMenu:{
                handler: function (newVL){
                    if(newVL && !this.replaceable) {
                        this.replaceable = this.getAppMenus[this.mainSize]
                    }
                }
            }
        },
        methods: {
            ...mapMutations([
            ]),
            switchMainList(){
                this.setRoute.liteTemplate(this.$route, this.$router, !this.routeFilter.noList, !this.routeFilter.noList)
            },
            onClickMainNavItem: function (route) {
                if(!route.name) return false;
                if(this.route_name !== route.name) {
                    this.$router.push({name: route.name})
                }
            },
            onClickDropdownNavItem: function (route) {
                if(!route) return false
                this.showItemTypeMore = false
                this.replaceable = route
                if(this.route_name !== route.name) {
                    this.$router.push({name: route.name})
                }
            },
        },
        created() {
        },
        mounted() {
        },
        updated() {
        }
    }
</script>

<style scoped>

</style>