// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, hasFilter} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        unitsFullLoad: false,
        unitsGroups: [],

        units: [],
        unit_active: null,
    },
    actions: {

        async fetchUnitsGroups({commit, dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchUnitsGroups', time: Date.now() / 1000})

            //let fullList = !hasFilter(args)
            const params = getApiProps('unitsGroups', args)

            this.$api.unitsgroups.getAll(params)
                .then((response) => {
                    if (response.status < 400 && !response.data.error) {
                        commit('updateUnitsGroups', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsGroups', inprogress: false})
                });
        },

        async fetchUnits/*all*/({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnits', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('units', args)

                this.$api.units.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            if(!fullList) commit('updateUnits', response.data)
                            else commit('updateUnitsFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchUnits', inprogress: false})
            });
        },
        async fetchUnitsAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchUnitsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsChanged', time: Date.now() / 1000})
            dispatch('fetchUnits', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.units.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchUnits', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setUnitsFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsChanged', inprogress: false})
                });
        },
        async fetchUnitsChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchUnitsChanged', time: Date.now() / 1000})
            dispatch('fetchUnits', args)
                .then(() => {
                    dispatch('fetchUnits', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsChanged', inprogress: false})
                });
        },

        async fetchUnitFullInfo({commit, getters}, id) {
            if (!getters.apiToken) {
                return false
            }
            const params = getApiProps('units')
            this.$api.units.getFull(id, {...params, ...with_removed})
                .then((response) => {
                    if (response.status < 400 && !response.data.error) {
                        commit('updateUnit', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async saveUnit({dispatch}, unit) {
            let fn = (unit.id) ? 'updateUnit' : 'createUnit'
            return dispatch(fn, unit);
        },
        async createUnit({commit, dispatch}, unit) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.create(unit, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnit({commit, dispatch}, unit) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.update(unit.id, unit, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnit({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteUnit', id)
                            else commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async restoreUnit({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.restore(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitPermanently({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units')
                this.$api.units.deletePermanently(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnit', id)
                            dispatch('fetchUnitsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async fetchUnitLiveVideo({getters}, id) {//commit,
            if (!getters.apiToken) {
                return false
            }
            let rs = false
            await this.$api.units.liveVideo(id, {})
                .then((response) => {
                    console.log(response)
                    rs = response.data
                })
                .catch((error) => {
                    console.error(error);
                });

            return rs
        },

        async saveUnitCounters({getters}, id) {//commit,
            if (!getters.apiToken) {
                return false
            }
            let rs = true
            let unit = getters.unitsByIds[id]
            await this.$api.units.updateCounters(id, unit.counters)
                .then((response) => {
                    console.log(response)
                    //commit('updateUnit', response.data)
                })
                .catch((error) => {
                    console.error(error);
                    rs = false
                });
            return rs
        },

        async sendUnitCmd({getters}, args) {
            if (!getters.apiToken) {
                return
            }
            return new Promise((resolve, reject) => {
                this.$api.units.sendCmd(args.id, args)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            //???
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setUnitsFullLoad(state, FullLoad) {
            state.unitsFullLoad = state.unitsFullLoad || FullLoad
        },
        setUnitActive(state, id) {
            state.unit_active = id
        },

        updateUnitsGroups(state, nGroups) {
            if (!state.unitsGroups.length) {
                nGroups = nGroups.map(g => {
                    if (g?.name) g.name_ = g.name.toLocaleLowerCase()
                    return g //Object.freeze(g))
                })
                nGroups.sort(sortByName)
                state.unitsGroups = nGroups
                // const chunks = arraySplitIntoChunks(nGroups)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.unitsGroups.push(...chunk);
                //     setTimeout(() => {
                //         requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nGroups.forEach(function (nGroup) {
                if (nGroup?.name) nGroup.name_ = nGroup.name.toLocaleLowerCase()
                let i = state.unitsGroups.findIndex(g => (g.id == nGroup.id))
                if (i < 0) {
                    state.unitsGroups.push(nGroup) //(Object.freeze(nGroup))
                } else {
                    nGroup = {...state.unitsGroups[i], ...nGroup}
                    state.unitsGroups[i] = nGroup //Object.freeze(nGroup)
                }
            })

            let nIds = nGroups.map(g => g.id)
            let removed = state.unitsGroups.filter(g => !nIds.includes(g.id))
            if (removed.length) {
                state.unitsGroups = state.unitsGroups.filter(g => nIds.includes(g.id))
            }
        },

        updateUnitsFullList(state, nUnits) {
            if (!state.units.length) {
                nUnits = nUnits.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nUnits.sort(sortByName)
                state.units = nUnits
                // const chunks = arraySplitIntoChunks(nUnits)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.units.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nUnits.forEach(function (nUnit) {
                if (nUnit?.name) nUnit.name_ = nUnit.name.toLocaleLowerCase()
                let i = state.units.findIndex(u => (u.id == nUnit.id))
                if (i < 0) {
                    state.units.push(nUnit) //(Object.freeze(nUnit))
                } else
                if(!state.unitsFullLoad || state.units[i][changedField] != nUnit[changedField]) {
                    nUnit = {...state.units[i], ...nUnit}
                    state.units[i] = nUnit //Object.freeze(nUnit)
                }
            })

            // let Ids = state.units.map(u=> u.id)
            let nIds = nUnits.map(u => u.id)
            let removedIds = state.units.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.units.findIndex(u => (u.id == removedId))
                if(i > 0) {
                    state.units.splice(i, 1)
                }
            })
        },
        updateUnits(state, nUnits) {
            if (!state.units.length) {
                nUnits = nUnits.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nUnits.sort(sortByName)
                state.units = nUnits
                // const chunks = arraySplitIntoChunks(nUnits)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.units.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nUnits.forEach(function (nUnit) {
                if (nUnit?.name) nUnit.name_ = nUnit.name.toLocaleLowerCase()
                let i = state.units.findIndex(u => (u.id == nUnit.id))
                if (i < 0) {
                    state.units.push(nUnit) //(Object.freeze(nUnit))
                } else
                if(!state.unitsFullLoad || state.units[i][changedField] != nUnit[changedField]) {
                    nUnit = {...state.units[i], ...nUnit}
                    state.units[i] = nUnit //Object.freeze(nUnit)
                }
            })
        },
        updateUnit(state, nUnit) {
            if (nUnit?.name) nUnit.name_ = nUnit.name.toLocaleLowerCase()
            let i = state.units.findIndex(u => (u.id == nUnit.id))
            if (i < 0) {
                state.units.push(nUnit) //(Object.freeze(nUnit))
            } else
            if(!state.unitsFullLoad || state.units[i][changedField] != nUnit[changedField]) {
                nUnit = {...state.units[i], ...nUnit}
                state.units[i] = nUnit //Object.freeze(nUnit)
            }
        },
        deleteUnit(state, id) {
            let i = state.units.findIndex(u => (u.id == id))
            if(i > 0) {
                state.units.splice(i, 1)
            }
        },

        clearUnits(state) {
            state.units = []
            state.unitsFullLoad = false
        },
        clearUnitsMaxSpeed(state) {
            state.units = state.units.map(u => {
                return {...u, changed__time: 0, max_speed: null}
            })
        },
    },
    getters: {
        isUnitsFullLoad(state) {
            return state.unitsFullLoad
        },
        unitsOutputsById(state, getters) {
            return state.units.reduce((out, u) => {
                let lmsg = getters.unitsLmsgsByIds[u.id]
                out[u.id] = (u.outputs || []).map(o => {
                    let sensor = (lmsg?.sensors || {})[o.param] || {}
                    let value = sensor.value
                    return {
                        name: o.name,
                        view: o.name > '' && o.param > '',
                        // value: value,
                        value: (value === null || value === undefined) ? 'null' : (value ? 'on' : 'off'),
                    }
                })
                return out
            },{})
        },
        unitsInputsById(state, getters){
            return state.units.reduce((out, u) => {
                let lmsg = getters.unitsLmsgsByIds[u.id]
                out[u.id] = (u.inputs || []).map(i => {
                    let sensor = (lmsg?.sensors || {})[i.param] || {}
                    let value = sensor.value
                    return {
                        name: i.name,
                        view: i.name > '' && i.param > '',
                        // value: value,
                        value: (value === null || value === undefined) ? 'null' : (value ? 'on' : 'off'),
                    }
                })
                return out
            },{})
        },
        getUnitActiveLmsg(state) {
            let unitId = state.unit_active
            let lmsg = state.unitsLmsgs.find(lmsg => lmsg.id_unit == unitId) || {}
            return lmsg
        },

        unitsGroups(state) {
            return state.unitsGroups.filter(g => {
                return g.units && g.units.length
            })
        },
        unitsGroupsByIds(state) {
            return state.unitsGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g
                return groupsByIds
            }, {})
        },
        sortedUnitsGroupsIds(state) {
            let unitsGroups = state.unitsGroups
            unitsGroups.sort(function (a, b) {
                let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            return unitsGroups.map(g => g.id)
        },

        units(state) {
            return state.units
        },
        firstActivatedUnitId(state, getters) {
            let units = getters.units.filter(u => getters.unitsNotExpiredIds.includes(u.id))
            units.sort(function (a, b) {
                let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            return units[0]?.id || 0
        },
        unitsNotExpiredIds(state, getters) {
            return state.units
                .filter(u => (u?.license?.isExpired === false) || (u?.license?.expired?.utc >= getters.getTimeToday))
                .map(u => u.id)
        },
        unitsByIds(state) {
            return state.units.reduce((unitsByIds, unit) => {
                unitsByIds[unit.id] = unit
                return unitsByIds
            }, {})
        },
        unitsAccessRightsByIds(state){
            return state.units.reduce((unitsAccessRightsByIds, unit) => {
                unitsAccessRightsByIds[unit.id] = unit?.access_right || 0
                return unitsAccessRightsByIds
            }, {})
        },
        sortedUnitsIds(state) {
            let units = state.units
            units.sort(sortByName)
            return units.map(u => u.id)
        },

        getUnitLicenseById(state, getters) {
            return state.units.reduce((unitsByIds, unit) => {
                let license = unit.license
                unitsByIds[unit.id] = {
                    ...license,
                    isExpired: license?.isExpired === true,
                    isNotExpired: license?.isExpired === false,
                    isActive: license?.isExpired === false || license?.expired?.utc >= getters.getTimeToday,
                    isAssigned: license?.isExpired === false && !license?.expired,
                    isActivated: !!license?.expired,
                }
                return unitsByIds
            }, {})
        },

        getUnitsMarkers: (state, getters) => {
            return state.units.reduce((markersByUnitIds, unit) => {
                let lmsg = getters.unitsLmsgsByIds[unit.id] || {}
                // if (lmsg && lmsg?.latlng)// && unitLmsgs.timeAgo < 30*24*60*60)
                {
                    // let type = consts.FilterSearchLists.Units.types.find(t => t.id == unit.type)
                    // let unit_icon = "common__"+(type ? type.icon : 'car')+"_lg"
                    let unit_icon = unit.icon || 'map__car_type_sedan'
                    let icon_color = unit.icon_color || '#003B79'
                    let icon_has_halo = !(!unit.icon_halo)
                    let style = 'color: ' + icon_color + ';'
                    if (lmsg?.course) style += 'transform: rotate(' + lmsg?.course + 'deg);'
                    let img_src =
                        '/img/icons.svg#' + unit_icon
                    // '/img/unit/'+unit_icon+'.svg'
                    // '/img/unit/common__car.png'
                    let iconHtml =
                        '<div class="unit-marker ' + (icon_has_halo ? 'unit-marker-has-halo' : '') + '">' +
                        '<div class="unit-marker-svg-icon" style="' + style + '">' +
                        // '<img class="svg-icon-unit-marker" src="'+img_src+'" sty-le="color:'+icon_color+'" style="'+style+'" width="50px" height="50px" >' +
                        '<svg class="svg-icon-unit-marker" xmlns="http://www.w3.org/2000/svg">' +
                        '<use xlink:href="' + img_src + '"/>' +
                        '</svg>' +
                        '</div>' +
                        // '<img class="unit-marker-icon" src="'+unit_icon+'" />'+
                        // '<img class="unit-ignition-icon" src="/img/ignition_icon.png" />'+
                        // '<img class="unit-moving-icon" src="/img/moving_icon.png" />'+
                        // '<img class="unit-status-icon" src="/img/unit-icon-status-motion.png" style="transform: rotateZ('+lmsg?.course+'deg)'+';"/>'+
                        //                                 // /img/unit-icon-status-parking-more-5-minutes.png
                        //                                 // /img/unit-icon-status-parking-more-15-minutes.png
                        '<div class="leaflet-label unit-marker-label" >' + unit.name + '</div>' +
                        '</div>'

                    markersByUnitIds[unit.id] = {
                        id: unit.id,
                        name: unit.name,
                        // type: unit.type,
                        icon: unit_icon,
                        icon_color: icon_color,
                        icon_has_halo: icon_has_halo,

                        time: lmsg?.time,
                        latlng: lmsg?.latlng,
                        course: lmsg?.course,
                        // speed: lmsg?.speed,
                        iconHtml: iconHtml,
                    }
                }
                return markersByUnitIds
            }, {})
        },
    }
}
