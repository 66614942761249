import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        geozonesGroups: [],

        geozones: [],
        geozone_active: null,
    },
    actions: {
        async setGeozoneActive({ commit } , id){
            commit('setGeozoneActive' , id)
        },
        async setGeoitem({dispatch, commit}, id) {
            let val = id ? [id] : []
            commit('setAllGeoitemsShowOnMap', val)
            dispatch('setMapBoundsByGeozones', val)
        },
        async fetchGeozonesGroups({ commit, dispatch, getters }, args) {
            if(!getters.apiToken) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchGeozonesGroups', time: Date.now() / 1000})

            //let fullList = !hasFilter(args)
            const params = getApiProps('geozones', args)

            this.$api.geozonesgroups.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeozonesGroups', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeozonesGroups', inprogress: false})
                });
        },

        async fetchGeozones/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGeozones', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('geozones', args)

                this.$api.geozones.getAll(params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            if(!fullList) commit('updateGeozones', response.data)
                            else commit('updateGeozonesFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchGeozones', inprogress: false})
            });
        },
        async fetchGeozonesAll({dispatch, /*commit,*/ getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchGeozonesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchGeozonesChanged', time: Date.now() / 1000})
            dispatch('fetchGeozones', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.geozones.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchGeozones', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                //if(fetch.length) commit('setGeozonesFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchGeozonesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchGeozonesChanged', inprogress: false})
                });
        },
        async fetchGeozonesChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchGeozonesChanged', time: Date.now() / 1000})
            dispatch('fetchGeozones', args)
                .then(() => {
                    dispatch('fetchGeozones', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeozonesChanged', inprogress: false})
                });
        },

        async saveGeozone({ dispatch }, geozone) {
            let fn = (geozone.id) ? 'updateGeozone' : 'createGeozone'
            return dispatch(fn, geozone);
        },
        async createGeozone({ commit, dispatch }, geozone) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones')
                this.$api.geozones.create(geozone, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeozone', {...response.data, points: geozone.points})
                            dispatch('fetchGeozonesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateGeozone({ commit, dispatch }, geozone) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones')
                this.$api.geozones.update(geozone.id, geozone, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeozone', {...response.data, points: geozone.points})
                            dispatch('fetchGeozonesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteGeozone({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.geozones.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteGeozone', id)
                            dispatch('fetchGeozonesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setGeozoneActive(state , id){
            state.geozone_active = id
        },
        updateGeozonesGroups(state, nGroups) {
            if(!state.geozonesGroups.length) {
                state.geozonesGroups = nGroups.map(g => Object.freeze(g))
                return true
            }

            let nId = nGroups.map(ng=> ng.id)
            let groups = state.geozonesGroups.filter( g => nId.includes(g.id) )
            if(groups.length !== state.geozonesGroups.length){
                state.geozonesGroups = groups.map(g => Object.freeze(g))
            }
            nGroups.forEach(function (nGroup) {
                let i = state.geozonesGroups.findIndex(g => (g.id == nGroup.id))
                if(i<0) {
                    state.geozonesGroups.push(Object.freeze(nGroup))
                }else{
                    state.geozonesGroups[i] = Object.freeze({...state.geozonesGroups[i], ...nGroup})
                }
            })
        },
        updateGeozonesFullList(state, nGeozones) {
            if(!state.geozones.length) {
                state.geozones = nGeozones.map(u => Object.freeze(u))
                return true
            }

            nGeozones.forEach(function (nGeozone) {
                let i = state.geozones.findIndex(u => (u.id == nGeozone.id))
                if(i<0) {
                    state.geozones.push(Object.freeze(nGeozone))
                }else
                if(!state.geozonesFullLoad || state.geozones[i][changedField] != nGeozone[changedField]) {
                    state.geozones[i] = Object.freeze({...state.geozones[i], ...nGeozone})
                }
            })

            let nId = nGeozones.map(nu=> nu.id)
            let removedIds = state.geozones.filter(g => !nId.includes(g.id)).map(g => g.id)
            removedIds.forEach(removedId => {
                let i = state.geozones.findIndex(g => (g.id == removedId))
                if (i > 0) {
                    state.geozones.splice(i, 1)
                }
            })
        },
        updateGeozones(state, nGeozones) {
            if(!state.geozones.length) {
                state.geozones = nGeozones.map(u => Object.freeze(u))
                return true
            }

            nGeozones.forEach(function (nGeozone) {
                let i = state.geozones.findIndex(u => (u.id == nGeozone.id))
                if(i<0) {
                    state.geozones.push(Object.freeze(nGeozone))
                }else
                if(!state.geozonesFullLoad || state.geozones[i][changedField] != nGeozone[changedField]) {
                    state.geozones[i] = Object.freeze({...state.geozones[i], ...nGeozone})
                }
            })
        },
        updateGeozone(state, nGeozone) {
            let i = state.geozones.findIndex(u => (u.id == nGeozone.id))
            if(i<0) {
                state.geozones.push(Object.freeze(nGeozone))
            }else
            if(!state.geozonesFullLoad || state.geozones[i][changedField] != nGeozone[changedField]) {
                state.geozones[i] = Object.freeze({...state.geozones[i], ...nGeozone})
            }
        },
        deleteGeozone(state, id) {
            let i = state.geozones.findIndex(g => (g.id == id))
            if(i > 0) {
                state.geozones.splice(i, 1)
            }
        },
        clearGeozones(state) {
            state.geozones = []
            state.geozonesFullLoad = false
        },
    },
    getters: {
        getGeozoneActive(state){
            return state.geozone_active
        },
        geozonesGroups(state){
            return state.geozonesGroups.filter(g => {
                return g.geozones && g.geozones.length
            })
        },
        geozonesGroupsByIds(state){
            return state.geozonesGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g
                return groupsByIds
            }, {})
        },
        geozonesCount(state) {
            return state.geozones.length
        },
        geozones(state){
            return state.geozones
        },
        geozonesByIds(state){
            // return state.geozonesObj
            return state.geozones.reduce((geozonesById, g) => {
                geozonesById[g.id] = g
                return geozonesById
            }, {})
        },

        geozonesLOptions(state){
            return state.geozones.reduce((geozoneOptions, geozone) => {
                if (geozone.type && geozone.points && geozone.points.length)
                {
                    let color = (geozone.color ? geozone.color : '#00ff00')
                    let options = {
                        geozoneId: geozone.id,
                        type: geozone.type,
                        name: geozone.name,
                        description: geozone.descr,
                        maxSpeed: geozone.maxSpeed,
                        points: geozone.points,
                        radius: geozone.radius,
                        area: geozone.surface_area,
                        color: color,
                        fillColor: color,
                        fillOpacity: 0.5
                    }
                    if(geozone.type === 'corridor'){
                        options.layerType='polyline'
                        options.polyline_radius=geozone.radius;
                        options.corridor= geozone.radius/2;
                    }
                    geozoneOptions.push(options)
                }
                return geozoneOptions
            }, [])
        },
    }
}
