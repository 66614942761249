<template>
    <div class="weather" :class="{'weather-extended': !extendedSize, 'weather-hide-on-map': !latlng}">
        <SpinnerIcon class="size-spinner" v-if="!hasUnitWeather && latlng" />
        <template v-else-if="latlng">
            <div class="weather__block">
                <div class="weather__time-info">
                    <div>
                        <div class="weather__time weather__font"><SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getTimeFormat"/></div>
                        <div class="weather__date weather__font"><SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getDateFormat"/></div>
                    </div>
                    <ul class="weather__info-list" v-if="listExtended.length && $consts.app.$isPrometheus">
                        <li class="weather__info-item weather__font weather__list-text" v-for="(item, i) in listExtended" :key="i" >
                            <img style="height: 18px; width: 18px" :src="require(`@/assets/image/weather__${item.icon}.svg`)" alt="">
                            <!-- <SvgIcon :name="'weather__'+item.icon" class="icon" style="width: 30px; height: 30px"/> -->
                            {{item.value}}<span class="weather__list-meaning ">{{ item.unit }}</span>
                        </li>
                    </ul>
                </div>
                <div class="weather__main-info">
                    <div class="weather-icon">
                        <SvgIcon class="icon weather-icon__icon" :name="weather_icon"/>
                    </div>
                    <div class="weather__value weather__font">
                        <img class="image" src="@/assets/image/gradus.png" alt="">
                        <div class="text info-units">
                            {{ temperature }}
                            <span class="badge">
                                <SvgIcon class="icon" style="width: 9px" name="circle__radius"/>
                                {{ temperatureUnits }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="weather__block" v-if="$consts.app.$isIntuit">
                <ul class="weather__info-list" v-if="listExtended.length">
                    <li class="weather__info-item weather__font weather__list-text" v-for="(item, i) in listExtended" :key="i" >
                        <img style="height: 30px; width: 30px" :src="require(`@/assets/image/weather__${item.icon}.svg`)" alt="">
                        <!-- <SvgIcon :name="'weather__'+item.icon" class="icon" style="width: 30px; height: 30px"/> -->
                        {{item.value}}<span class="weather__list-meaning ">{{ item.unit }}</span>
                    </li>
                </ul>
                <div class="weather__text weather__font">
                    {{weather_text}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";

export default {
    name: "WeatherWidget",
    components: {SectionCurrentDateTime},
    props: {
        'unitId': [String, Number],
        'extendedSize':{
            type: Number,
            default: () => { return 0 }
        }
    },
    data() {
        return {
            weathersList: [
                {name: 'wind',     icon: 'wind',},
                {name: 'humidity', icon: 'raindrop',},
                {name: 'pressure', icon: 'pressure',},
                // {name: 'precip',    icon: 'precip', },
                // {name: 'feelslike', icon: 'thermometer', },
                // {name: 'gust',      icon: 'wind', }
            ]
        }
    },
    computed:{
        ...mapGetters([
            "getAppUserSettings",
            "getUserTimeZone",
            "getTimeFormat",
            "getDateFormat",
            "getWeathers",
            "unitsLmsgsByIds",
            "getAppUserUnits",
            "getAppUserUnitsValue",
        ]),
        temperatureUnits(){
            return this.getAppUserUnits.temperature
        },
        latlng(){
            return (this.unitsLmsgsByIds[this.unitId] || {}).latlng
        },
        unitWeather(){
            if(!this.latlng) {
                //ToDo fix
                // if(this.unitId > 0) this.fetchUnitWeather(this.unitId)
                return false
            }
            // console.log('weather at latlng', {...this.latlng}, this.getWeathers.length)
            let weathers = this.getWeathers
                .filter(w => w.request_time > Date.now()/1000 -3600)
                .map(w => {
                    let lat = (w.latLng.lat*1.0) - (this.latlng.lat*1.0)
                    let lon = (w.latLng.lng*1.0) - (this.latlng.lng*1.0)
                    let distances = Math.sqrt(Math.pow(lat,2) + Math.pow(lon,2))
                    let time_ago = Date.now()/1000 - w.request_time
                    return (distances < 0.2)? {...w, distances, time_ago} : null
                })
                .filter(w => w)

            // console.log('weather at latlng', {...this.latlng}, weathers)
            weathers.sort(function (a,b){
                //let time = a.time_ago - b.time_ago
                return (a.distances - b.distances)/0.2// + (time/3600)
            })

            let weather = weathers[0]
            // console.log('weather at latlng', {...this.latlng}, {...weather}, weathers)
            return weather? {...weather} : null
        },
        hasUnitWeather(){
            return !!this.unitWeather
        },
        temperature(){
            let temperature = this.unitWeather?.temperature
            return temperature ? Math.round( temperature[this.temperatureUnits]) : '- '
        },
        weather_text(){
            return (this.unitWeather?.text) ? this.unitWeather.text[this.$i18n.locale] || this.unitWeather.text.en || this.unitWeather.text : ''
        },
        weather_icon(){
            return this.unitWeather?.icon ? ('weather__'+this.unitWeather.icon) : 'empty'
        },
        weatherExtended(){
            return this.unitWeather?.extendWeather || []
        },
        listExtended(){
            let weatherExtended = this.weathersList.map(w => {
                let item = this.weatherExtended[w.name]
                if(typeof item != 'object') {
                    item = null;
                } else {
                    item = Object.fromEntries(Object.entries(item).filter(([key]) => this.getAppUserUnitsValue.includes(key)));
                    if(item) item = {value: item[Object.keys(item)[0]], unit:Object.keys(item)[0],...w}
                }
                return item
            })
            .filter(w => w)
            return weatherExtended.slice(0,this.extendedSize)
        },
    },
    watch: {
        unitId: {
            handler() {
                this.debouncedFetchUnitWeather()
            },
        },
        latlng: {
            handler() {
                this.debouncedFetchUnitWeather()
            },
        },
        hasUnitWeather: {
            handler() {
                this.debouncedFetchUnitWeather()
            },
        },
    },
    methods:{
        ...mapActions([
            "fetchWeather",
            "fetchUnitWeather"
        ]),
        handlerUnitWeather(){
            // console.log('watch weather', this.unitId, this.latlng, this.hasUnitWeather, this.unitWeather)
            if (this.unitId && this.latlng && !this.hasUnitWeather) {
                // console.log('watch weather', 'fetchUnitWeather', this.unitId)
                this.fetchUnitWeather(this.unitId)
            }
        },
    },
    created: function () {
        this.debouncedFetchUnitWeather = debounce(this.handlerUnitWeather, 500)
    },
}
</script>

<style scoped>

</style>