const RESOURCE_NAME = '/video-status';
const RESOURCE__NAME = '/video-statuses';

export default axios => ({
    getAll(id, params) {
        return axios.get(`/units${RESOURCE__NAME}`, {params});
    },
    find(id, params) {
        return axios.get(`/units/${id}${RESOURCE_NAME}`, {}, {params});
    },
});