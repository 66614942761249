// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        unitsKeysFullLoad: true,
        unitsKeys: [],
    },
    actions: {
        async fetchUnitsKeys4Order({ commit, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                const params = getApiProps('keys', args)
                this.$api.unitskeysorders.getKeys(args.orderId, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            let order = {orderId: args.orderId, keys: response.data}
                            commit('updateUnitsKeys4Order', order)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        async fetchUnitsKeys/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
            if(!getters.apiToken) {
                return reject(false)
            }
            dispatch('setLastCall', {name: 'fetchUnitsKeys', time: Date.now() / 1000})

            let fullList = !hasFilter(args)
            const params = getApiProps('keys', args)

            this.$api.unitskeys.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data?.error) {
                        if(!fullList) commit('updateUnitsKeys', response.data)
                        else commit('updateUnitsKeysFullList', response.data)
                        resolve(true)
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(false)
                })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchUnitsKeys', inprogress: false})
            });
        },
        async fetchUnitsKeysAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchUnitsKeysAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', time: Date.now() / 1000})
            dispatch('fetchUnitsKeys', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.unitsKeys.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchUnitsKeys', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setUnitsKeysFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsKeysAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', inprogress: false})
                });
        },
        async fetchUnitsKeysChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', time: Date.now() / 1000})
            dispatch('fetchUnitsKeys', args)
                .then(() => {
                    //dispatch('fetchUnitsKeys', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsKeysChanged', inprogress: false})
                });
        },

        async saveUnitsKey({ dispatch }, key) {
            let fn = (key.id) ? 'updateUnitsKey' : 'createUnitsKey'
            return dispatch(fn, key);
        },
        async createUnitsKey({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keys')
                this.$api.unitskeys.create(key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeysOrder', {id: response.data?.order_id || 0})
                            //???
                            dispatch('fetchUnitsKeysChanged')
                            dispatch('fetchUnitsKeysOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnitsKey({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keys')
                this.$api.unitskeys.update(key.id, key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeysOrder', {id: response.data?.order_id || 0})
                            //???
                            dispatch('fetchUnitsKeysChanged')
                            dispatch('fetchUnitsKeysOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitsKey({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitskeys.delete(id)
                    .then((response) => {
                        if(response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeysOrder', {id: response.data?.order_id || 0})
                            //???
                            dispatch('fetchUnitsKeysChanged')
                            dispatch('fetchUnitsKeysOrdersChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async calcUnitsKeysTimeLeft({ commit }){
            commit('calcUnitsKeysTimeLeft')
        },

        //sayHello() {}
    },
    mutations: {
        setUnitsKeysFullLoad(state, FullLoad) {
            state.unitsKeysFullLoad = state.unitsKeysFullLoad || FullLoad
        },
        updateUnitsKeys(state, nKeys) {
            if(!state.unitsKeys.length) {
                state.unitsKeys = nKeys.map(k => Object.freeze(k))
                return true
            }

            // let nId = nKeys.map(nk => nk.id)
            // let keys = state.unitsKeys.filter(k => nId.includes(k.id) )
            // if(keys.length !== state.unitsKeys.length) {
            //     state.unitsKeys = keys.map(k => Object.freeze(k))
            // }
            // console.log('updateUnitsKeys', state.unitsKeys.length)
            nKeys.forEach(function (nKey) {
                let i = state.unitsKeys.findIndex(k => (k.id == nKey.id))
                if(i<0) {
                    state.unitsKeys.push(Object.freeze(nKey))
                }else
                if(!state.unitsKeysFullLoad || state.unitsKeys[i][changedField] != nKey[changedField]) {
                    state.unitsKeys[i] = Object.freeze({...state.unitsKeys[i], ...nKey})
                }
            })
        },
        updateUnitsKeys4Order(state, order) {
            let nKeys = order.keys
            let nId = nKeys.map(nk => nk.id)
            let keys = state.unitsKeys.filter(k => k.order_id != order.orderId || nId.includes(k.id) )
            if(keys.length !== state.unitsKeys.length) {
                state.unitsKeys = keys.map(k => Object.freeze(k))
            }
            // console.log('updateUnits', state.unitsKeys.length)
            nKeys.forEach(function (nKey) {
                let i = state.unitsKeys.findIndex(k => (k.id == nKey.id))
                if(i<0) {
                    state.unitsKeys.push(Object.freeze(nKey))
                }else
                if(!state.unitsKeysFullLoad || state.unitsKeys[i][changedField] != nKey[changedField]) {
                    state.unitsKeys[i] = Object.freeze({...state.unitsKeys[i], ...nKey})
                }
            })
        },
        updateUnitsKey(state, nKey) {
            let i = state.unitsKeys.findIndex(k => (k.id == nKey.id))
            if(i<0) {
                state.unitsKeys.push(Object.freeze(nKey))
            }else
            if(!state.unitsKeysFullLoad || state.unitsKeys[i][changedField] != nKey[changedField]) {
                state.unitsKeys[i] = Object.freeze({...state.unitsKeys[i], ...nKey})
            }
        },
        deleteUnitsKey(state, id) {
            let i = state.unitsKeys.findIndex(k => (k.id == id))
            if(i > 0) {
                state.unitsKeys.splice(i, 1)
            }
        },

        calcUnitsKeysTimeLeft(state){
            console.error('!!!calcUnitsKeysTimeLeft')
            state.unitsKeys.forEach((key,i) => {
                key = {...key}
                key.time__left--
                state.unitsKeys[i] = Object.freeze(key)
            })
        },
        clearUnitsKeys(state) {
            state.unitsKeys = []
            state.unitsKeysFullLoad = false
        }

    },
    getters: {
        isUnitsKeysFullLoad(state) {
            return state.unitsKeysFullLoad
        },
        unitsKeys(state){
            return state.unitsKeys
        },
        unitsKeysFree(state){
            return state.unitsKeys.filter(k => !k.activation?.utc && !k.is_transferred && !k?.removed?.utc && k.time__left>0)
        },

        unitsKeysFreeSum(_, getters){
            if(getters.isSuperAdmin) return 0
            return getters.unitsKeysFree.length
        },
        unitsKeysFreeByProductsIds(_, getters){
            if(getters.isSuperAdmin) return []

            return getters.unitsKeysFree.reduce((productKeys, key) => {
                let product = productKeys.find(p => p.productId == key.productId)
                if (!product) {
                    product = {productId: key.productId, keys: []}
                    productKeys.push(product)
                }
                product.keys.push(key.id)
                return productKeys
            }, [])
        },

        unitsKeysFreeByOrdersIds(_, getters){
            //if (getters.isSuperAdmin) return []

            return getters.unitsKeysFree.reduce((orderKeys, k) => {
                let order = orderKeys.find(o => o.order_id == k.order_id)
                if (!order) {
                    order = {order_id: k.order_id, keys: []}
                    orderKeys.push(order)
                }
                order.keys.push(k.id)
                return orderKeys
            }, [])
        },
        unitsKeysFreeSumText(_, getters){//ToDo
            if (getters.isSuperAdmin) return {}

            let products = getters.getProducts
            products.sort(function (a, b) {
                return (a.type == b.type) ? a.days - b.days : ((a.type > b.type) ? 1 : -1);
            })

            let balance = {commercial: [], trial: [{name: 'Trial', count: 0}]}
            balance = products.reduce((balanceInfo, p) => {
                let keys = getters.unitsKeysFree.filter(k => p.id == k.productId)
                let count = keys.length
                if (count) {
                    if (p.type == 'trial') {
                        balanceInfo.trial[0]['count'] += count
                    } else {
                        let inArray = balanceInfo.commercial.find(t => t.name == p.name)
                        if (inArray) {
                            inArray['count'] += count
                        } else {
                            balanceInfo.commercial.push({name: p.name, count, days: p.days, type: p.type, productId: p.id})
                        }
                    }
                }
                return balanceInfo
            }, balance)
            if (!balance.trial[0]['count']) delete balance.trial

            return balance
        },
        unitsKeysByIds(state){
            return state.unitsKeys.reduce((keysByIds, k) => {
                keysByIds[k.id] = k
                return keysByIds
            }, {})
        },
    }
}
