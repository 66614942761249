// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = [
    {
        path: '/alarms',
        name: consts.routerNames.alarms.main,
        meta: {
            mainRouteName: consts.routerNames.alarms.main,
            list: 'AlarmsList',
            showMap: true
        },
        component: () => import('@/views/prometheus/portal/AlarmsPage.vue')
    },
];

export default routes;