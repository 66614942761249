import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
function getUnitDefaultRouteParams (unitId) {
    let unit = store.getters.unitsByIds[unitId]
    localStorage.setItem(store.getters.getUserStorageKey+'.unit.active', unitId)
    let template = unit?.hw_type
    template = consts.unitHwTypeTransformation[template] || 'xtrack'
    let routeName = consts.routerNames?.units?.type[template] || consts.routerNames?.units?.type?.xtrack
    return {
        routeName,
        query: {
            unitId,
            template
        }
    }
}
const routes = [
    {
        path: '/units',
        name: consts.routerNames?.units?.main,
        meta: {
            list: 'UnitsList',
            mainRouteName: consts.routerNames?.units?.main,
            pageLayout: 'block-position-unit',
            showMap: true,
            weatherInMap: true,
            pageClass: 'unit-md-page',
            setRoute: {
                units: (route, router) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('units', route);
                        return false;
                    }

                    router.push({
                        name: consts.routerNames?.units?.main,
                        params: {},
                        query: {...route.query}
                    })
                },
                returnToParent:(route, router) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn(consts.routerNames?.units?.main+' returnToParent', route);
                        return false;
                    }
                    let name = route.meta?.parentRouteName || consts.routerNames?.units?.main;
                    router.push({
                        name: name,
                        params: {
                            ...route.params,
                        },
                        query: {...route.query}
                    })
                },

                unitId: (route, router, unitId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('unitId', route);
                        return false;
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams.routeName.info,
                        params: {
                            ...routeParams.query
                        },
                        query: {...route.query}
                    })
                },
                info(route, router, unitId) {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('unitId', route);
                        return false;
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams.routeName.info,
                        params: {
                            ...routeParams.query
                        },
                        query: {...route.query}
                    })
                },
                liteTemplate: (route, router, lite) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('unitLiteTemplate', route);
                        return false;
                    }
                    let unitId = route.params.unitId
                    let unit = store.getters.unitsByIds[unitId]
                    let type = unit?.hw_type
                    type = consts.unitHwTypeTransformation[type] || type
                    let routeName = consts.routerNames?.units?.type[type] || consts.routerNames?.units?.type?.xtrack
                    if(lite && !consts.templates?.unitLite?.extensions?.byUnitHwType.includes(type)) {
                        routeName = consts.templates.unitLite.routeName
                    }
                    router.push({
                        name: routeName,
                        params: {...route.params,unitId},
                        query: {...route.query}
                    })
                },
                unitEdit: (route, router, unitId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('unitEdit', route);
                        return false;
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams.routeName.edit,
                        params: {
                            ...routeParams.query
                        },
                        query: {...route.query}
                    })

                },
                unitInfo: (route, router, unitId, date) => {
                    console.warn('unitInfo', route, router, unitId, date)
                    return false;
                    // if(route.meta?.mainRouteName !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitInfo', route);
                    //     return false;
                    // }
                    // if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    // let routeParams = getUnitDefaultRouteParams(unitId)
                    // router.push({
                    //     name: routeParams.routeName + '.info',
                    //     params: {
                    //         ...routeParams.query,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitReefer: (route, router, unitId) => {
                    console.warn('unitReefer', route, router, unitId)
                    return false;
                    // if(route.meta?.mainRouteName !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitReefer', route);
                    //     return false;
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.type?.xcool + '.reefer',
                    //     params: {
                    //         unitId,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitStationAnalytics: (route, router, unitId) => {
                    console.warn('unitStationAnalytics', route, router, unitId)
                    return false;
                    // if(route.meta?.mainRouteName !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitStationAnalytics', route);
                    //     return false;
                    // }
                    // if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    // let routeParams = getUnitDefaultRouteParams(unitId)
                    // router.push({
                    //     name: routeParams.routeName + '.analytics',
                    //     params: {
                    //         ...routeParams.query,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitGenerator: (route, router, unitId, name) => {
                    console.warn('unitGenerator', route, router, unitId, name)
                    return false;
                    // if(route.meta?.mainRouteName !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitGenerator', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.type?.xpower + '.' + name,
                    //     params: {
                    //         unitId,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitsFilter: (route, router, filter) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('unitsFilter', route);
                        return false
                    }
                    router.push({
                        name: route.name,
                        params: {
                            ...route.params
                        },
                        query: {
                            name: filter?.name || undefined,
                            movement: filter?.movement || undefined,
                            signal: filter?.signal || undefined,
                        }
                    })
                },
                liveVideo: (route, router, unitId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('liveVideo', route);
                        return false
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams.routeName?.main + '.live-video',
                        params: {
                            ...routeParams.query,
                        },
                        query: {...route.query}
                    })
                },
                'video-event': (route, router, unitId, eventXid) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('video-event', route);
                        return false
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams.routeName.main + '.video-event',
                        params: {
                            ...routeParams.query,
                            eventXid,
                        },
                        query: {...route.query}
                    })
                },
                'video-request': (route, router, unitId, eventXid) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('video-request', route);
                        return false
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams.routeName?.main + '.video-request',
                        params: {
                            ...routeParams.query,
                            eventXid,
                        },
                        query: {...route.query}
                    })
                },
                fastGeoitem: (route, router, unitId) => {
                    console.warn('fastGeoitem', route, router, unitId)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('fastGeoitem', route);
                    //     return false
                    // }
                    // let unit = store.getters.unitsByIds[unitId]
                    // let type = unit?.hw_type
                    // type = consts.unitHwTypeTransformation[type] || type
                    // let routeName = (consts.routerNames?.units?.type[type] || consts.routerNames?.units?.type?.xtrack)
                    // router.push({
                    //     name: routeName + '.fastGeozone',
                    //     params: {
                    //         unitId,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                track: (route, router, unitId, date = store.getters.getDateToday) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('track', route);
                        return false
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: consts.routerNames?.units?.track?.main,
                        params: {
                            ...routeParams.query,
                            date,
                        },
                        query: {
                            ...route.query
                        }
                    })
                },
                trackDate: (route, router, date = store.getters.getDateToday) => {
                    console.log('trackDate', date , route)
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('trackDate', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: consts.routerNames?.units?.track?.main,
                        params: {
                            ...routeParams.query,
                            date,
                        },
                        query: {...route.query}
                    })
                },
                trackXid: (route, router, type, trackXid, timeFrom, timeTo, trackColor) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('trackXid', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    let date = route?.params?.date
                    trackColor = trackColor ? 'track-color' : ''
                    router.push({
                        name: consts.routerNames?.units?.track?.xid,
                        params: {
                            ...routeParams.query,
                            date,
                            type,
                            trackXid,
                            timeFrom,
                            timeTo,
                            trackColor,
                        },
                        query: {...route.query}
                    })
                },
                trackColor: (route, router, trackColor) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('trackColor', route);
                        return false
                    }
                    trackColor = trackColor ? 'track-color' : ''
                    router.push({
                        name: route.name,
                        params: {
                            ...route?.params,
                            trackColor,
                        },
                        query: {...route.query}
                    })
                },
                trackEvent: (route, router, trackEventType, trackEventXid) => {
                    console.warn('trackEvent', route, router, trackEventType, trackEventXid)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('trackEvent', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.track?.event_xid,
                    //     params: {
                    //         ...route.params,
                    //         trackEventType,
                    //         trackEventXid,
                    //     },
                    //     query: {...route.query}
                    // })
                },

                unitsGroupEdit: (route, router, groupId) => {
                    console.warn('unitsGroupEdit', route, router, groupId)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitsGroupEdit', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.edit?.group,
                    //     params: {
                    //         groupId,
                    //     },
                    //     query: {...route.query}
                    // })

                },
                unitCommand: (route, router, unitId) => {
                    console.warn('unitCommand', route, router, unitId)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitCommand', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.commands?.main,
                    //     params: {
                    //         unitId,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitsGroupCommand: (route, router, groupId) => {
                    console.warn('unitsGroupCommand', route, router, groupId)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitsGroupCommand', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.commands?.group,
                    //     params: {
                    //         groupId,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitsLocator: (route, router, unitId) => {
                    console.warn('unitsLocator', route, router, unitId)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitsLocator', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.locator,
                    //     params: {
                    //         unitId,
                    //     },
                    //     query: {...route.query}
                    // })
                },
                unitChat: (route, router, unitId) => {
                    console.warn('unitChat', route, router, unitId)
                    return false;
                    // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                    //     if(this.$isDevelopment) console.warn('unitChat', route);
                    //     return false
                    // }
                    // router.push({
                    //     name: consts.routerNames?.units?.chat,
                    //     params: {
                    //         unitId,
                    //     },
                    //     query: {...route.query}
                    // })
                },

                sensors: (route, router, unitId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('sensors', route);
                        return false
                    }
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams?.routeName?.sensors,
                        params: {
                            ...routeParams.query,
                        },
                        query: {...route.query}
                    })
                },

                notificationsId: (route, router, notificationType, notificationId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('notificationsId', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    if(!store.getters.getUnitLicenseById[unitId]?.isActive) return false;
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    let date = route?.params?.date || store.getters.getDateToday
                    router.push({
                        name: consts.routerNames?.units?.info+'.notifications',
                        params: {
                            ...routeParams.query,
                            date,
                            notificationType,
                            notificationId,
                        },
                        query: {...route.query}
                    })
                },
            },
        },
        components:{
            default: () => import('@/views/prometheus/portal/UnitsPage.vue'),
        },
        children: [
            {
                path: ':unitId(\\d+)',
                name: consts.routerNames?.units?.id,
                meta:{
                    mainRouteName: consts.routerNames?.units?.main,
                    parentRouteName: consts.routerNames?.units?.main,
                    sectionInfoByRoute: true,
                },
                props: {
                    default: function (route) {
                        return {unitId: route.params.unitId}
                    },
                },
                components: {
                    default: () => import('@/components/units/pageInfo/UnitInfo.prometheus.vue'),
                },
                children: [
                    {
                        path: ':template',//xvision
                        name: consts.routerNames?.units?.type?.xvision?.main,
                        meta:{
                            mainRouteName: consts.routerNames?.units?.main,
                            parentRouteName: consts.routerNames?.units?.main,
                            // pageClass: 'prometheus-portal__xvision',//prometheus-portal__info
                            weatherInMap: true,
                        },
                        props: {
                            default: (route) => ({unitId: route.params.unitId}),
                        },
                        components: {
                            default: () => import('@/components/units/info/UnitInfo_xvision.prometheus.vue'),
                        },
                        children:[
                            //info
                            {
                                path: 'info',
                                name: consts.routerNames?.units?.type?.xvision?.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                    pageClass: 'prometheus-portal__xvision',//prometheus-portal__info
                                    isVideo: true,
                                },
                                props: {
                                    default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId, infoList: ['notifications', 'sensors', 'driver']}),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_hasVideo.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'video-event/:eventXid',
                                        name:  consts.routerNames?.units?.type?.xvision?.videoEvent,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                            parentRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal__xvision__video',
                                            isVideo: true,
                                            weatherInMap: false,
                                            // sectionInfoByRoute: false,
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-event/VideoEvent.vue')
                                        },
                                    },
                                    {
                                        path: 'live-video',
                                        name: consts.routerNames?.units?.type?.xvision?.liveVideo,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                            parentRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal__xvision__video-live',
                                            isVideo: true,
                                            sectionInfoByRoute: false,
                                        },
                                        props: {
                                            LiveVideoComponent: (route) => ({unitId: route.params.unitId})
                                        },
                                        components: {
                                            LiveVideoComponent: () => import('@/components/video/control/live-video/LiveVideo.vue'),
                                        }
                                    },
                                    {
                                        path: 'video-request/:eventXid',
                                        name: consts.routerNames?.units?.type?.xvision?.videoRequest,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                            parentRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal__xvision__video',
                                            isVideo: true,
                                            showMapInfo: false,
                                            // sectionInfoByRoute: false,
                                        },
                                        props: {
                                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                                        },
                                        components: {
                                            VideoEventComponent: () => import('@/components/video/control/video-request/VideoRequest.vue')
                                        },
                                    },
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.xvision?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xvision?.info,
                                            pageClass: 'prometheus-portal__xvision prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            },//info xvision
                            {
                                path: 'info',
                                name: consts.routerNames?.units?.type?.xcool?.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xcool?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xcool?.main,
                                    // pageClass: 'prometheus-portal__xvision',//prometheus-portal__info

                                },
                                props: {
                                    //default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_routeView.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.xcool?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xcool?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xcool?.info,
                                            pageClass: 'prometheus-portal__xcool prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            },//info xcool
                            {
                                path: 'info',
                                name: consts.routerNames?.units?.type?.xtrack?.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xtrack?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xtrack?.main,
                                    //pageClass: 'prometheus-portal__xvision',//prometheus-portal__info

                                },
                                props: {
                                    // default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_routeView.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.xtrack?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xtrack?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xtrack?.info,
                                            pageClass: 'prometheus-portal__xtrack prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            }, //info xtrack
                            {
                                path: 'info',
                                name: consts.routerNames?.units?.type?.xpower?.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xpower?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xpower?.main,
                                    //pageClass: 'prometheus-portal__xvision',//prometheus-portal__info

                                },
                                props: {
                                    // default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_routeView.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.xpower?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xpower?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xpower?.info,
                                            pageClass: 'prometheus-portal__xpower prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            }, //info xpower
                            {
                                path: 'info',
                                name: consts.routerNames?.units?.type?.xlink?.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xlink?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xlink?.main,
                                    //pageClass: 'prometheus-portal__xvision',//prometheus-portal__info

                                },
                                props: {
                                    // default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    // default: () => import('@/components/units/info/UnitInfo_hasVideo.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.xlink?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xlink?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xlink?.info,
                                            pageClass: 'prometheus-portal__xlink prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            }, //info xlink
                            {
                                path: 'info',
                                name: consts.routerNames?.units?.type?.xcargo?.info,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.type?.xcargo?.info,
                                    parentRouteName: consts.routerNames?.units?.type?.xcargo?.main,
                                    //pageClass: 'prometheus-portal__xvision',//prometheus-portal__info

                                },
                                props: {
                                    // default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    default: () => import('@/components/units/info/UnitInfo_routeView.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_info.vue'),
                                },
                                children: [
                                    {
                                        path: 'sensors',
                                        name: consts.routerNames?.units?.type?.xcargo?.sensors,
                                        meta: {
                                            mainRouteName: consts.routerNames?.units?.type?.xcargo?.info,
                                            parentRouteName: consts.routerNames?.units?.type?.xcargo?.info,
                                            pageClass: 'prometheus-portal__xcargo prometheus-portal-sensors',
                                        },
                                        props: {
                                            default: () => ({title: 'Sensors'})
                                        },
                                        components: {
                                            default: () => import('@/components/units/info/UnitInfo_test.vue'),
                                        },
                                    },//sensors
                                ]
                            }, //info xcargo

                            //tarck
                            {
                                path: 'track/:date?',
                                name: consts.routerNames?.units?.track?.main,
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.track?.main,
                                    parentRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                    //pageClass: '',

                                },
                                props: {
                                    // default: (route) => ({unitId: route.params.unitId}),
                                    info: (route) => ({unitId: route.params.unitId}),
                                },
                                components: {
                                    // default: () => import('@/components/units/info/UnitInfo_hasVideo.prometheus.vue'),
                                    info: () => import('@/components/units/pageInfo/UnitInfo_content_track.vue'),
                                },
                                children: [
                                    {
                                        path: ':type/:trackXid:/:timeFrom/:timeTo/:trackColor?/:speed?',
                                        name: consts.routerNames?.units?.track?.xid,
                                        meta:{
                                            mainRouteName: consts.routerNames?.units?.track?.main,
                                            parentRouteName: consts.routerNames?.units?.track?.main,
                                        },
                                        children: [
                                            {
                                                path: ':trackEventType/:trackEventXid',
                                                name: consts.routerNames?.units?.track?.event_xid,
                                                meta:{
                                                    mainRouteName: consts.routerNames?.units?.track?.main,
                                                    parentRouteName: consts.routerNames?.units?.track?.main,
                                                },
                                            },
                                        ]
                                    },
                                ]
                            },//track

                            {
                                path: 'edit',
                                name: consts.routerNames?.units?.type?.xvision?.main + '.edit',
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.main,
                                    parentRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                    sectionInfoByRoute: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: route.params.unitId,
                                        time: Date.now(),
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                                }
                            },

                            {
                                path: 'fast-geozone',
                                name: consts.routerNames?.units?.type?.xvision?.main + '.fastGeozone',
                                meta: {
                                    mainRouteName: consts.routerNames?.units?.main,
                                    parentRouteName: consts.routerNames?.units?.type?.xvision?.main,
                                    sectionInfoByRoute: true,
                                    fastGeozone: true,
                                },
                                props: {
                                    sectionInfoByRoute: (route) => ({
                                        unitId: +route.params?.unitId,
                                        time: Date.now()
                                    })
                                },
                                components: {
                                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                                }
                            },
                        ],
                    },
                ]
            },
        ],
        // beforeEnter: (to, from) => {
        //     if(from.name && from.name != 'Login'){// First load not correct getUserStorageKey == 0
        //         let unitsIdPage = {name: 'Units', params: {unitId: localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')}}
        //         if(to.name == 'Units' && !to?.params?.unitId) {
        //             return to?.params?.unitId ? to : unitsIdPage
        //         } else{
        //             return true
        //         }
        //     } else
        //     if(to?.params?.unitId) {// First load
        //         return {...to, params: {}}
        //     }
        // },
        // Vuex version !!!
        // beforeEnter: (to) => {
        //     // let unitId = localStorage.getItem(this.getUserStorageKey + '.unit.active')*1
        //     let unitId = to.params?.unitId
        //     if (!unitId) {
        //         unitId = localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')*1
        //         to.params.unitId = unitId
        //     }
        //     //return false
        // },
    },
];

export default routes