 // import {objectComparison} from "@/lib/lib";

 export default {
    state: {
        statistics:{
            users: [],
            units: [],
            orders: [],
            keys: {
                status: [],
                products: [],
                units: [],
            }
        },
        statisticsCalendar: [],
    },
    actions: {
        fetchStatisticsUsers({commit, getters}, args){
            if(!getters.apiToken) {
                return
            }
            let params = {
                scale: args?.scale,
                sub_users: args?.sub_users
            };
            let userId = args?.userId || getters.getAppUserId;
            let period = args?.period || 'this_month';

            this.$api.statistics.statisticsUsers(userId, period, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsUsers', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchStatisticsUnits({commit, getters}, args){
            if(!getters.apiToken) {
                return
            }
            let params = {
                scale: args?.scale,
                sub_users: args?.sub_users
            };
            let userId = args?.userId || getters.getAppUserId;
            let period = args?.period || 'this_month';

            this.$api.statistics.statisticsUnits(userId, period, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsUnits', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchStatisticsOrders({commit, getters}, args){
            if(!getters.apiToken) {
                return
            }
            let params = {
                scale: args?.scale,
                sub_users: args?.sub_users
            };
            let userId = args?.userId || getters.getAppUserId;
            let period = args?.period || 'this_month';

            this.$api.statistics.statisticsOrders(userId, period, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsOrders', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchStatisticsKeysByStatus({commit, getters}, userId){
            if(!getters.apiToken) {
                return
            }
            let params = {};
            userId = userId || getters.getAppUserId

            this.$api.statistics.statisticsKeysByStatus(userId, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsKeysByStatus', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchStatisticsKeysByProducts({commit, getters}, userId){
            if(!getters.apiToken) {
                return
            }
            let params = {};
            userId = userId || getters.getAppUserId

            this.$api.statistics.statisticsKeysByProducts(userId, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsKeysByProducts', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchStatisticsKeysByUnits({commit, getters}, userId){
            if(!getters.apiToken) {
                return
            }
            let params = {};
            userId = userId || getters.getAppUserId

            this.$api.statistics.statisticsKeysByUnits(userId, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsKeysByUnits', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchStatisticsCalendar({commit, getters}, args){
            if(!getters.apiToken) {
                return
            }
            let params = {
                scale: args?.scale,
                //sub_users: args?.sub_users
            };
            //let userId = args?.userId || getters.getAppUserId;
            let period = args?.period || 'this_month';

            this.$api.statistics.statisticsCalendar(period, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        // console.log(response.data)
                        commit('setStatisticsCalendar', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mutations: {
        setStatisticsUsers(state, data){
            state.statistics.users = data
        },
        setStatisticsUnits(state, data){
            state.statistics.units = data
        },
        setStatisticsOrders(state, data){
            state.statistics.orders = data
        },
        setStatisticsKeysByStatus(state, data){
            state.statistics.keys.status = data
        },
        setStatisticsKeysByProducts(state, data){
            state.statistics.keys.products = data
        },
        setStatisticsKeysByUnits(state, data){
            state.statistics.keys.units = data
        },

        setStatisticsCalendar(state, data){
            state.statisticsCalendar = data
        }
    },
    getters: {
        getStatisticsUsers(state){
            return state.statistics.users
        },
        getStatisticsUnits(state){
            return state.statistics.units
        },
        getStatisticsOrders(state){
            return state.statistics.orders
        },
        getStatisticsKeysByStatus(state){
            return state.statistics.keys.status
        },
        getStatisticsKeysByProducts(state){
            return state.statistics.keys.products
        },
        getStatisticsKeysByUnits(state){
            return state.statistics.keys.units
        },

        getStatisticsCalendar(state){
            return state.statisticsCalendar
        },
    }
}
