// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
const routes = [
    {
        path: '/reports',
        name: consts.routerNames?.reports?.main,
        meta: {
            mainRouteName: consts.routerNames?.reports?.main,
            setRoute: {
                reportTemplate: (route, router, template) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.reports?.main) {
                        if(this.$isDevelopment) console.warn('reports', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames?.reports?.template,
                        params: {
                            template
                        },
                        query: {...route.query}
                    })
                },
                reportId: (route, router, reportId) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.reports?.main) {
                        if(this.$isDevelopment) console.warn('units', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames?.reports?.id,
                        params: {
                            reportId
                        },
                        query: {...route.query}
                    })
                }
            },
        },
        component: () => import('@/views/prometheus/portal/ReportsPage.vue'),
        children: [
            {
                path: ':template',
                name: consts.routerNames?.reports?.template,
                meta: {
                    mainRouteName: consts.routerNames?.reports?.main,
                },
                props: {
                    default: (route) => {
                        return {
                            template: route.params?.template
                        }
                    }
                },
                components:{
                    default: () => import("@/components/reports/ReportItem_info.vue"),
                }
            },
            {
                path: ':reportId',
                name: consts.routerNames?.reports?.id,
                meta: {
                    mainRouteName: consts.routerNames?.reports?.main,
                },
                props: {
                    default: (route) => {
                        return {
                            reportId: route.params?.reportId
                        }
                    }
                },
                components:{
                    default: () => import("@/components/reports/ReportId.vue"),
                }
            }
        ]
    },
];

export default routes;