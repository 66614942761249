// import Vue from 'vue'
import consts from "@/consts"
import {uniq} from "lodash/array";
import range from "lodash/range";
import {getApiProps, hasFilter} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        productsFullLoad: false,
        products: [],
    },
    actions: {
        async fetchProducts/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchProducts', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('products', args)

                this.$api.products.getAll(params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            if(!fullList) commit('updateProducts', response.data)
                            else commit('updateProductsFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchProducts', inprogress: false})
            });
        },
        async fetchProductsAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchProductsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchProductsChanged', time: Date.now() / 1000})
            dispatch('fetchProducts', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.getProducts.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchProducts', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setProductsFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchProductsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchProductsChanged', inprogress: false})
                });
        },
        async fetchProductsChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchProductsChanged', time: Date.now() / 1000})
            dispatch('fetchProducts', args)
                .then(() => {
                    dispatch('fetchProducts', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchProductsChanged', inprogress: false})
                });
        },
        async saveProduct({ dispatch }, product) {
            let fn = (product.id) ? 'updateProduct' : 'createProduct'
            return dispatch(fn, product);
        },
        async createProduct({ commit, dispatch }, product) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('products')
                this.$api.products.create(product, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateProduct', response.data)
                            dispatch('fetchProductsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateProduct({ commit, dispatch }, product) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('products')
                this.$api.products.update(product.id, product, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateProduct', response.data)
                            dispatch('fetchProductsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteProduct({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.products.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteProduct', id)
                            dispatch('fetchProductsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations:{
        setProductsFullLoad(state, FullLoad) {
            state.productsFullLoad = state.productsFullLoad || FullLoad
        },
        updateProductsFullList(state, nProducts) {
            if(!state.products.length) {
                state.products = nProducts.map(p => Object.freeze(p))
                return true
            }

            nProducts.forEach(function (nProduct) {
                let i = state.products.findIndex(p => p.id == nProduct.id)
                if(i<0) {
                    state.products.push(Object.freeze(nProduct))
                }else
                if(!state.productsFullLoad || state.products[i][changedField] != nProduct[changedField]) {
                    state.products[i] = Object.freeze({...state.products[i], ...nProduct})
                }
            })

            let nIds = nProducts.map(np=> np.id)
            let removedIds = state.products.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.products.findIndex(u => (u.id == removedId))
                if(i > 0) {
                    state.products.splice(i, 1)
                }
            })
        },
        updateProducts(state, nProducts) {
            if(!state.products.length) {
                state.products = nProducts.map(p => Object.freeze(p))
                return true
            }

            nProducts.forEach(function (nProduct) {
                let i = state.products.findIndex(p => p.id == nProduct.id)
                if(i<0) {
                    state.products.push(Object.freeze(nProduct))
                }else
                if(!state.productsFullLoad || state.products[i][changedField] != nProduct[changedField]) {
                    state.products[i] = Object.freeze({...state.products[i], ...nProduct})
                }
            })
        },
        updateProduct(state, nProduct) {
            let i = state.products.findIndex(p => p.id == nProduct.id)
            if(i<0) {
                state.products.push(Object.freeze(nProduct))
            }else
            if(!state.productsFullLoad || state.products[i][changedField] != nProduct[changedField]) {
                state.products[i] = Object.freeze({...state.products[i], ...nProduct})
            }
        },
        deleteProduct(state, id) {
            let i = state.products.findIndex(p => (p.id == id))
            if(i > 0) {
                state.products.splice(i, 1)
            }
        },
        clearProducts(state) {
            state.products = []
            state.productsFullLoad = false
        },
    },
    getters:{
        isProductsFullLoad(state) {
            return state.productsFullLoad
        },
        getProductsTypes(state) {
            return uniq(state.products.map(p => p.type))
        },
        getProductsByDays(state) {
            return state.products.reduce((productsByDays, product) => {
                let days = product.days
                if(!productsByDays[days]) productsByDays[days] = []
                productsByDays[days].push(product)
                return productsByDays
            },{})
        },
        getProducts(state){
            return state.products
        },
        getProductById(state){
            return state.products.reduce((productsByIds, product) => {
                productsByIds[product.id] = product
                return productsByIds
            }, {})
        }
    }
}