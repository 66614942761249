import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        notificationsGroups: [],
        notificationsGroups_index: [],
        notificationsFullLoad: false,
        notifications: [],
        notifications_index: [],
    },
    actions: {
        async fetchNotificationsGroups({ commit, dispatch, getters }, args) {
            if(!getters.apiToken) {
                return
            }
            dispatch('setLastCall', {name: 'fetchNotificationsGroups', time: Date.now() / 1000})

            //let fullList = !hasFilter(args)
            const params = getApiProps('notificationsGroups', args)

            this.$api.notifications.getAllGroups(params)
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateNotificationsGroups', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchNotificationsGroups', inprogress: false})
            });
        },
        
        async fetchNotifications/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if(!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchNotifications', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('notifications', args)

                this.$api.notifications.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        if(!fullList) commit('updateNotifications', response.data)
                        else commit('updateNotificationsFullList', response.data)
                        resolve(true)
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(false)
                });
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchNotifications', inprogress: false})
            });
        },
        async fetchNotificationsAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchNotificationsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchNotificationsChanged', time: Date.now() / 1000})
            dispatch('fetchNotifications', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.notifications.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchNotifications', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setNotificationsFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchNotificationsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchNotificationsChanged', inprogress: false})
                });
        },
        async fetchNotificationsChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchNotificationsChanged', time: Date.now() / 1000})
            dispatch('fetchNotifications', args)
                .then(() => {
                    dispatch('fetchNotifications', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchNotificationsChanged', inprogress: false})
                });
        },
        async saveNotificationGeozone(context, args) {
            let action = (args.enabled === true) ? 'add' : 'remove'
            return new Promise((resolve, reject) => {
                this.$api.notifications.patchGeozones(args.notifId, action, {id_geozone: args.id_geozone})
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            //???
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        updateNotificationsGroups(state, groups) {
            let collator = new Intl.Collator();
            //ToDo remove
            groups.sort(function (a, b) {
                let cmp = collator.compare(a.name, b.name)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })

            state.notificationsGroups = groups
            state.notificationsGroups_index = groups.map(g => g.id)
        },
        setNotificationsFullLoad(state, FullLoad) {
            state.notificationsFullLoad = state.notificationsFullLoad || FullLoad
        },
        updateNotificationsFullList(state, notifications) {//ToDo fix
            let collator = new Intl.Collator();
            let nIds = notifications.map(u => u.id)
            //ToDo remove
            notifications.sort(function (a, b) {
                let cmp = collator.compare(a.name, b.name)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            notifications.forEach(nNotif => {
                let i = state.notifications.findIndex(oNotif => oNotif.id == nNotif.id)
                if(i < 0) {
                    state.notifications.push(nNotif)
                } else
                if(!state.notificationsFullLoad || state.notifications[i][changedField] != nNotif[changedField]) {
                    state.notifications[i] = {...state.notifications[i], ...nNotif}
                }
            })
            state.notifications_index = nIds;

            let removedIds = state.notifications.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.notifications.findIndex(u => (u.id == removedId))
                if(i > 0) {
                    state.notifications.splice(i, 1)
                }
            })

            let ns = notifications.reduce((notificationsByType, n) => {
                let type = n["type0"]
                if(type>'') {
                    if (!notificationsByType[type]) notificationsByType[type] = []
                    notificationsByType[type].push(n.id)
                }
                return notificationsByType
            }, {})

            state.notificationsGroups.forEach(g => {
                g.notifications = ns[g.id] || []
            })
        },
        updateNotifications(state, notifications) {//ToDo fix
            let collator = new Intl.Collator();
            //ToDo remove
            notifications.sort(function (a, b) {
                let cmp = collator.compare(a.name, b.name)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            notifications.forEach(nNotif => {
                let i = state.notifications.findIndex(oNotif => oNotif.id == nNotif.id)
                if(i < 0) {
                    state.notifications.push(nNotif)
                } else
                if(!state.notificationsFullLoad || state.notifications[i][changedField] != nNotif[changedField]) {
                    state.notifications[i] = {...state.notifications[i], ...nNotif}
                }
            })
            state.notifications_index = notifications.map(n => n.id)

            let ns = notifications.reduce((notificationsByType, n) => {
                let type = n["type0"]
                if(type>'') {
                    if (!notificationsByType[type]) notificationsByType[type] = []
                    notificationsByType[type].push(n.id)
                }
                return notificationsByType
            }, {})

            state.notificationsGroups.forEach(g => {
                g.notifications = ns[g.id] || []
            })
        },
        clearNotifications(state) {
            state.notifications = []
            state.notificationsFullLoad = false
        },
    },
    getters: {
        isNotificationsFullLoad(state) {
            return state.notificationsFullLoad
        },
        notificationsGroups(state){
            return state.notificationsGroups.filter(g => {
                return g.notifications && g.notifications.length
            })
        },
        notificationsGroupsByIds(state){
            return state.notificationsGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g
                return groupsByIds
            }, {})
        },
        notifications(state){
            return state.notifications
        },
        notificationsByIds(state){
            return state.notifications.reduce((notificationsByIds, notification) => {
                notificationsByIds[notification.id] = notification
                return notificationsByIds
            }, {})
        },
    }
}
