import {getApiProps, hasFilter} from "@/lib/lib";

export default {

    state: {
        video_statuses: [],
    },
    actions: {
        async fetchVideoStatuses/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchVideoStatuses', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('videoStatuses', args)

                this.$api.videoStatuses.getAll(params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            if(!fullList) commit('updateVideoStatuses', response.data)
                            else commit('updateVideoStatusesFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchVideoStatuses', inprogress: false})
            });
        },
        async fetchVideoStatusesAll({dispatch, /*commit,*/ getters}) {
            if (!getters.apiToken) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchVideoStatusesAll', time: Date.now() / 1000})
            //dispatch('setLastCall', {name: 'fetchVideoStatusesChanged', time: Date.now() / 1000})
            dispatch('fetchVideoStatuses', {lite: true})
                .then((resp) => {
                    if (resp) {
                        // let count = getters.video_statuses.length
                        // let pages = Math.ceil(count/consts.querySettings.pageSize)
                        // let fetch = range(pages).map(i => {
                        //     let page = i+1;
                        //     return new Promise((resolve) =>
                        //         setTimeout(async () => resolve(await dispatch('fetchVideoStatuses', {page})), page*100)
                        //     )
                        // });
                        // Promise.all(fetch)
                        //     .then(() => {
                        //         //if(fetch.length) commit('setVideoStatusesFullLoad', true)
                        //     });
                    }
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchVideoStatusesAll', inprogress: false})
                    //dispatch('setLastCall', {name: 'fetchVideoStatusesChanged', inprogress: false})
                });
        },
        fetchVideoStatus({ commit, dispatch, getters }, args) {
            // return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    // reject(false)
                    return false;
                }
                dispatch('setLastCall', {name: 'fetchVideoStatus', time: Date.now() / 1000})
                const params = getApiProps('videoStatuses', args)
                this.$api.videoStatuses.find(args.id, params)
                    .then((response) => {
                        if (response?.status < 400 && !response?.data?.error) {
                            commit('updateVideoStatus', response?.data)
                            // resolve(true)
                        } else {
                            // resolve(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        // reject(false)
                    })
            // })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchVideoStatus', inprogress: false})
            })
        },

    },
    mutations: {
        updateVideoStatusesFullList(state, nVideoStatuses) {
            if(!state.video_statuses.length) {
                state.video_statuses = nVideoStatuses.map(u => Object.freeze(u))
                return true
            }

            nVideoStatuses.forEach(function (nVideoStatus) {
                let i = state.video_statuses.findIndex(u => (u.id == nVideoStatus.id))
                if(i<0) {
                    state.video_statuses.push(Object.freeze(nVideoStatus))
                }else{
                    state.video_statuses[i] = Object.freeze({...state.video_statuses[i], ...nVideoStatus})
                }
            })

            let nId = nVideoStatuses.map(nu=> nu.id)
            let removedIds = state.video_statuses.filter(g => !nId.includes(g.id)).map(g => g.id)
            removedIds.forEach(removedId => {
                let i = state.video_statuses.findIndex(g => (g.id == removedId))
                if (i > 0) {
                    state.video_statuses.splice(i, 1)
                }
            })
        },
        updateVideoStatuses(state, nVideoStatuses) {
            if(!state.video_statuses.length) {
                state.video_statuses = nVideoStatuses.map(u => Object.freeze(u))
                return true
            }

            nVideoStatuses.forEach(function (nVideoStatus) {
                let i = state.video_statuses.findIndex(u => (u.id == nVideoStatus.id))
                if(i<0) {
                    state.video_statuses.push(Object.freeze(nVideoStatus))
                }else{
                    state.video_statuses[i] = Object.freeze({...state.video_statuses[i], ...nVideoStatus})
                }
            })
        },
        updateVideoStatus(state, nVideoStatus) {
            let i = state.video_statuses.findIndex(u => (u.id == nVideoStatus.id))
            if(i<0) {
                state.video_statuses.push(Object.freeze(nVideoStatus))
            }else{
                state.video_statuses[i] = Object.freeze({...state.video_statuses[i], ...nVideoStatus})
            }
        },
        deleteVideoStatus(state, id) {
            let i = state.video_statuses.findIndex(g => (g.id == id))
            if(i > 0) {
                state.video_statuses.splice(i, 1)
            }
        },
    },
    getters: {
        getVideoStatusesCount(state) {
            return state.video_statuses.length
        },
        getVideoStatuses(state){
            return state.video_statuses
        },
        getVideoStatusesByUnitsIds(state){
            // return state.video_statusesObj
            return state.video_statuses.reduce((video_statusesById, video) => {
                video_statusesById[video.id] = video
                return video_statusesById
            }, {})
        },
        getVideoStatusesByTypes(state){
            // return state.video_statusesObj
            return state.video_statuses.reduce((video_statusesById, video) => {
                video_statusesById[video.hw_type] = video
                return video_statusesById
            }, {})
        },
    }
}
