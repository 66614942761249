// import consts from "@/consts";
// import store from '@/store;'
// import moment from "moment";
import statistics from '@/router/prometheus/admin/statistics.js';
import users from '@/router/prometheus/admin/users.js';
import units from '@/router/prometheus/admin/units.js';
import keys from '@/router/prometheus/admin/keys.js';
import subscriptions from '@/router/prometheus/admin/subscriptions.js';
import recycleBin from '@/router/prometheus/admin/recycleBin.js';
import userActions from '@/router/prometheus/admin/userActions.js';
import objectChanges from '@/router/prometheus/admin/objectChanges.js';
const routes = [
    ...statistics,
    ...users,
    ...units,
    ...keys,
    ...subscriptions,
    ...recycleBin,
    ...userActions,
    ...objectChanges,
    {//if route not found !!!
        path: '/:pathMatch(.*)*',
        name: 'null',
    }
]

export default routes