// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = [
    {
        path: '/users',
        name: consts.routerNames.users.main,
        meta: {
            mainRouteName: consts.routerNames.users.main,
            bodyGrid: "1X1",
            showHead: true,
            setRoute: {
                users: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('users', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.users.main,
                        params: {},
                        query: {}
                    })
                },
                usersClearQuery: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('usersClearQuery', route);
                        return false
                    }
                    router.push({
                        name: route.name,
                        params: {
                            ...route.params,
                        },
                        query: {}
                    })
                },
                usersEdit: (route, router, userId, filter) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('usersEdit', route);
                        return false
                    }
                    let query = filter ? {...route.query, ...filter} : route.query
                    router.push({
                        name: consts.routerNames.users.edit.main,
                        params: {
                            userId,
                        },
                        query: {...query}
                    })
                },
                userCustomizeEdit: (route, router, userId, filter) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('usersEdit', route);
                        return false
                    }
                    let query = filter ? {...route.query, ...filter} : route.query
                    router.push({
                        name: consts.routerNames.users.edit.customize,
                        params: {
                            userId,
                        },
                        query: {...query}
                    })
                },
                usersCreate: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('usersCreate', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.users.edit.create,
                        params: {
                        },
                        query: {...route.query}
                    })
                },
                userChangePassword: (route, router, userId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('userChangePassword', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.users.edit.password,
                        params: {
                            userId,
                        },
                        query: {...route.query}
                    })
                },
                usersHistory: (route, router, userId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('usersHistory', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.users.history,
                        params: {
                            userId,
                        },
                        query: {...route.query}
                    })
                },
                userSetupDns: (route, router, userId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('userSetupDns', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.users.edit.setup_dns,
                        params: {
                            userId,
                        },
                        query: {...route.query}
                    })
                },
                globalUserEdit: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.users.main) {
                        if(this.$isDevelopment) console.warn('globalUserEdit', route);
                        return false
                    }
                    router.push({
                        name: route.name + '.' + consts.routerNames.app.user.edit.default,
                        params: {},
                        query: {...route.query}
                    })
                },
            }
        },
        component: () => import('@/views/intuit/admin/UsersPage.vue'),
        children: [
            {
                path: 'app/edit',
                name: consts.routerNames.users.main+ '.' + consts.routerNames.app.user.edit.default,
                meta: {
                    sectionWidgetByRoute: true
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/app/sectionInfo/UserSettings.vue'),
                }
            },
            {
                path: 'create',
                name: consts.routerNames.users.edit.create,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            titlePosition: 'center',
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: () => ({
                        wrapper: 'EditWizard',
                        class: 'popup-body',
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/users/sectionInfo/UserEdit.vue'),
                }
            },
            {
                path: ':userId/edit',
                name: consts.routerNames.users.edit.main,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {
                    sectionWidgetByRoute: (route) => {
                        let active = route.query?.active;
                        let props = {
                            userId: route.params?.userId,
                            time: Date.now(),
                        }
                        if (active) props.activeComponent = active;
                        return props;
                    }
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/users/sectionInfo/UserEdit.vue'),
                }
            },
            {
                path: ':userId/edit/customize',
                name: consts.routerNames.users.edit.customize,
                meta: {
                    mainRouteName: consts.routerNames.users.main,
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            // class: 'popup-full',
                            titlePosition: 'center'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        userId: route.params.userId,
                        wrapper: 'EditWizard',
                        class: 'popup-body',
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/users/sectionInfo/UserCustomizeEdit.vue'),
                }
            },
            {
                path: ':userId/change-password',
                name: consts.routerNames.users.edit.password,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {
                    sectionWidgetByRoute: (route) => ({
                        userId: route.params?.userId,
                        time: Date.now(),
                        options: {components: ['UserEdit_Password_intuit']}
                    })
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/users/sectionInfo/UserEdit.vue'),
                }
            },
            {
                path: ':userId/history',
                name: consts.routerNames.users.history,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'user',
                        objectId: route.params.userId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            },
            {
                path: ':userId/setup-dns',
                name: consts.routerNames.users.edit.setup_dns,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            titlePosition: 'center',
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        userId: route.params.userId,
                        wrapper: 'EditWizard',
                        class: 'popup-body',
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/users/sectionPopup/WizardDnsSetup.vue'),
                }
            }
        ]
    },
];

export default routes;