<template>
    <section class="wizard is_new-design">
        <div class="wizard__head">
            <section class="wizard__body">
                <nav class="wizard-navigation">
                    <Stepper
                        :class="['custom-stepper', (menuList.length == 1 && 'stepper-one') ]"
                        :value="step"
                        :items="menuList"
                        :item="'menuSetting'"
                        @change="setStep"
                        v-if="!hideSteps && (!hideOneStep  && menuList.length == 1) || menuList.length > 1"
                    ><!-- v-show outbid -->
                        <template v-slot:menuSetting="{ props }">
                            <Step v-bind="props">
                                <div class="custom-step">
                                    <SvgIcon class="icon wizard__icon" :class="props.class" :name="props.icon"  />
                                </div>
                                <span class="step-label">{{props.name}}</span>
                            </Step>
                        </template>
                    </Stepper>
                    <slot></slot>
                </nav>
            </section>
        </div>
        <section class="wizard__bottom wizard-buttons">
            <button class="button button_view_base button_theme_primary mr__8"
                    :class="{'button-disabled' : !isValid}"
                    :disabled="!isValid"
                    @click="onStepNext"
            >
                {{next}}
            </button>
            <button class="button button_view_base button_theme_neutral"
                    :class="{'button-disabled' : step == 0}"
                    :disabled="step == 0"
                    @click="onStepBack"
            >
                {{back || $t('buttons.Back')}}
            </button>
        </section>
    </section>
</template>

<script>
import {Step, Stepper} from "@progress/kendo-vue-layout";

export default {
    name: "EditWizard",
    emits:['update:modelValue', 'save', 'cancel'],
    props: {
        'title': String,
        'components': Array,
        'modelValue': [Number, String],
        'action': String,
        'back': String,
        'hideSteps': {
            type: Boolean,
            default: () => false
        },
        'hideOneStep': {
            type: Boolean,
            default: () => true
        },
    },
    components: {
        Stepper,
        Step,
    },
    data() {
        return {}
    },
    computed: {
        menuList() {
            return this.components.map((m, step) => {
                let isValid = undefined;
                if (this.step >= step) {
                    isValid = !m.hasErrors
                }
                let icon = m.icon
                if(this.step >= step) {
                    if (isValid === false) {
                        icon = 'reefer-error'
                    } else
                    if (isValid) {
                        icon = 'common__checkbox'
                    }
                }
                return {...m, step, isValid, icon}
            })
        },
        componentsNoValid(){
            return this.menuList.filter( c => c.isValid === false ).map(c => c.component)
        },
        isValid(){
            return !this.componentsNoValid.length
        },
        isLastStep(){
            return this.step == (this.menuList.length - 1)
        },
        errors(){
            return this.components.filter(c => !!c.hasErrors).map(c => c.component)
        },
        subtitle(){
            let selected = this.components.find(c => c.component === this.component)
            return selected?.subtitle || ''
        },
        // fistStepNoValid(){
        //     return this.menuList.findIndex(c => c.isValid === false )
        // },
        // passedSteps(){
        //     return this.menuList.reverse().findIndex(c => c.isValid === true || c.isValid === false)
        // },
        component: {
            get(){ return this.modelValue },
            set(v){ this.$emit('update:modelValue', v) }
        },
        step: {
            get(){ return this.components.findIndex(c => c.component === this.component) || 0 },
            set(s){ this.component = this.components[s]?.component }
        },
        next(){
            return this.isLastStep ?  this.action : this.$t('buttons.Next')
        },
    },
    methods: {
        onCancel() {
            this.$emit('cancel')
        },
        onStepNext() {
            if(this.components[this.step]?.hasErrors){
                return false
            }
            if(this.isLastStep){
                return this.$emit('save')
            }
            this.step++
        },
        onStepBack() {
            if(this.step) this.step--
        },
        setStep(event){
            if(this.componentsNoValid.length && event.value > this.step) return;
            if((event.value <= this.step + 1)){
                this.step = event.value
            }
        },
    }
}
</script>

<style scoped>

</style>