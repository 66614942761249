<template>
    <div class="section__head-item balance" ><!-- no v-if -->
        <span class="balance__title" v-if="!isSuperAdmin">{{ title ? title : $t('text.Balance')}}:</span>
        <ul class="balance__list" v-for="(product, i) in unitsKeysFreeSumText" :key="i">
            <li class="balance__item" v-for="(key, i) in product" :key="i">
                <template v-if="isIconView">
                    <div class="balance__icon-view">
                        <div class="balance__icon-view-icon">
                            <SvgIcon name="products_bg" class="icon" :style="{color: colorsByDays[key.days]}"/>
                            <span class="balance__icon-view-icon-logo">
                                <img :src="require('@/assets/img/product_logo.png')">
                            </span>
                            <span class="balance__icon-view-text">
                                {{ $t('License.' + labelsByDays[key.days]) }}
                            </span>
                        </div>
                        <span class="balance__count">{{$t('Keys.Type.'+key.type)}}: {{key.count}}</span>
                    </div>
                </template>
                <template v-else>
                    <span class="balance__name">{{key.name.replace('License', '').trim()}}:</span>
                    <span class="balance__count">{{key.count}}</span>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "SectionBalance",
    components: {},
    props: {
        title: String,
        isIconView: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "apiToken",
            "isGuest",
            "isDealer",
            "getAppUser",
            "getUserStorageKey",
            "isSuperAdmin",
            "unitsKeysFreeSum",
            "unitsKeysFreeSumText",
        ]),
        labelsByDays(){
            return this.$consts.Licenses.params.reduce((labelsByDays, p) => {
                labelsByDays[p.days] = p.text
                return labelsByDays
            }, {})
        },
        colorsByDays(){
            return this.$consts.Licenses.params.reduce((colorsByDays, p) => {
                colorsByDays[p.days] = p.color
                return colorsByDays
            }, {})
        },
    },
    created() {
        // console.log('SectionBalance created');
    },
    mounted() {
        // console.log('SectionBalance mounted');
    },
    updated() {
        // console.log('SectionBalance updated');
    },
}
</script>

<style scoped>

</style>