// import Vue from 'vue'
// import {objectComparison} from "@/lib/lib";
import {getApiProps} from "@/lib/lib";

export default {
    state: {
        weathers: [],
    },
    actions: {
        async fetchWeathers({ commit, dispatch, getters }) {
            if(!getters.apiToken) {
                return
            }
            dispatch('setLastCall', {name: 'fetchWeathers', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchWeather', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitWeather', time: Date.now() / 1000})
            const params = getApiProps('weathers')
            this.$api.weather.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateWeathers', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchWeathers', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchWeather', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitWeather', inprogress: false})
                });
        },
        async fetchWeather({ commit, dispatch, getters }, latLon) {
            if(!getters.apiToken) {
                return
            }
            const params = getApiProps('weathers')
            if(typeof latLon !== 'string'){
                if(Array.isArray(latLon)){
                    latLon = latLon.join(',')
                }else
                if(latLon.lat && latLon.lon){
                    latLon = `${latLon.lat},${latLon.lon}`
                }else
                if(latLon.lat && latLon.lng){
                    latLon = `${latLon.lat},${latLon.lng}`
                }
            }
            if(!(latLon > '')) {
                console.error('Unknown latLon', {...latLon})
                return
            }
            dispatch('setLastCall', {name: 'fetchWeather', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitWeather', time: Date.now() / 1000})
            this.$api.weather.find(latLon, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateWeathers', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchWeather', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitWeather', inprogress: false})
                });
        },

        async fetchUnitWeather({ commit, dispatch, getters }, id) {//commit,
            if(!getters.apiToken) {
                return false
            }
            dispatch('setLastCall', {name: 'fetchWeather', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitWeather', time: Date.now() / 1000})
            await this.$api.units.weather(id, {})
                .then((response) => {
                    commit('updateWeathers', response.data)
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchWeather', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitWeather', inprogress: false})
                });
        },

    },
    mutations:{
        updateWeathers(state, nWeathers) {
            if(!state.weathers.length) {
                state.weathers = nWeathers.map(w => Object.freeze(w))
                return true
            }

            // let nId = nWeathers.map(nu=> nu.request_time)
            let time = Date.now()/1000
            let weathers = state.weathers.filter(w => w.request_time +30*60 > time)
            if(weathers.length !== state.weathers.length){
                state.weather = weathers.map(w => Object.freeze(w))
            }
            nWeathers.forEach(function (nWeather) {
                let isExists = state.weathers.find(w => w.latLng.lat == nWeather.latLng.lat && w.latLng.lon == nWeather.latLng.lon && w.request_time == nWeather.request_time)
                if(!isExists) state.weathers.push(Object.freeze(nWeather))
            })
        },
        updateWeather(state, nWeather) {
            let time = Date.now()/1000
            let weathers = state.weathers.filter(w => w.request_time +30*60 > time)
            let update = (weathers.length !== state.weathers.length)

            let isExists = weathers.find(w => w.latLng.lat == nWeather.latLng.lat && w.latLng.lon == nWeather.latLng.lon && w.request_time == nWeather.request_time)
            if(!isExists) weathers.push(Object.freeze(nWeather))
            if(!isExists || update){
                state.weathers = weathers.map(w => Object.freeze(w))
            }
        },
    },
    getters:{
        getWeathers(state){
            return state.weathers//.filter(w => w.request_time > Date.now()/1000 -3600)
        },
        getUnitActiveWeather(state, getters){
            let lmsg = getters.getUnitActiveLmsg
            if(!lmsg || !lmsg.latlng) return false
            let weathers = state.weathers
                //.filter(w => w.request_time > Date.now()/1000 -3600)
                .map(w => {
                    let lat = (w.latLng.lat*1.0) - (lmsg.latlng.lat*1.0)
                    let lon = (w.latLng.lng*1.0) - (lmsg.latlng.lng*1.0)
                    let distances = Math.sqrt(Math.pow(lat,2) + Math.pow(lon,2))
                    return {...w, distances}
                })
            weathers.sort(function (a,b){
                return a.distances - b.distances + (Math.pow((a.request_time - b.request_time),2))
            })
            return weathers[0]
        },
    }
}