// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps, hasFilter} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        unitsHwTypesFullLoad: false,
        unitsHwTypes: [],
    },
    actions: {
        async fetchUnitsHwTypes/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
                if(!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsHwTypes', time: Date.now() / 1000})

                let fullList = !hasFilter(args)
                const params = getApiProps('hwtype', args)

                this.$api.unitshwtypes.getAll(params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error){
                            if(!fullList) commit('updateUnitsHwTypes', response.data)
                            else commit('updateUnitsHwTypesFullList', response.data)
                            resolve(true)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        return reject(false)
                    });
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchUnitsHwTypes', inprogress: false})
            });
        },
        async fetchUnitsHwTypesAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchUnitsHwTypesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', time: Date.now() / 1000})
            dispatch('fetchUnitsHwTypes', {lite: true})
                .then((resp) => {
                    let notUse = false// no lite
                    if (notUse && resp) {
                        let count = getters.getUnitsHwTypes.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchUnitsHwTypes', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setUnitsHwTypesFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsHwTypesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', inprogress: false})
                });
        },
        async fetchUnitsHwTypesChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', time: Date.now() / 1000})
            dispatch('fetchUnitsHwTypes', args)
                .then(() => {
                    dispatch('fetchUnitsHwTypes', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', inprogress: false})
                });
        },
        async saveUnitsHwType({ dispatch }, unitsHwType) {
            let fn = (unitsHwType.id) ? 'updateUnitsHwType' : 'createUnitsHwType'
            return dispatch(fn, unitsHwType);
        },
        async createUnitsHwType({ commit, dispatch }, unitsHwType) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('hwtype')
                this.$api.unitshwtypes.create(unitsHwType, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsHwType', response.data)
                            dispatch('fetchUnitsHwTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnitsHwType({ commit, dispatch }, unitsHwType) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('hwtype')
                this.$api.unitshwtypes.update(unitsHwType.id, unitsHwType, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsHwType', response.data)
                            dispatch('fetchUnitsHwTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitsHwType({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitshwtypes.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnitsHwType', id)
                            dispatch('fetchUnitsHwTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations:{
        setUnitsHwTypesFullLoad(state, FullLoad) {
            state.unitsHwTypesFullLoad = state.unitsHwTypesFullLoad || FullLoad
        },
        updateUnitsHwTypesFullList(state, nUnitsHwTypes) {
            if(!state.unitsHwTypes.length) {
                state.unitsHwTypes = nUnitsHwTypes.map(hwt => Object.freeze(hwt))
                return true
            }

            nUnitsHwTypes.forEach(function (nUnitsHwType) {
                let i = state.unitsHwTypes.findIndex(hwt => (hwt.id == nUnitsHwType.id))
                if(i<0) {
                    state.unitsHwTypes.push(Object.freeze(nUnitsHwType))
                }else
                if(!state.unitsHwTypesFullLoad || state.unitsHwTypes[i][changedField] != nUnitsHwType[changedField]) {
                    state.unitsHwTypes[i] = Object.freeze({...state.unitsHwTypes[i], ...nUnitsHwType})
                }
            })

            let nIds = nUnitsHwTypes.map(np=> np.id)
            let removedIds = state.unitsHwTypes.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.unitsHwTypes.findIndex(u => (u.id == removedId))
                if(i > 0) {
                    state.unitsHwTypes.splice(i, 1)
                }
            })
        },
        updateUnitsHwTypes(state, nUnitsHwTypes) {
            if(!state.unitsHwTypes.length) {
                state.unitsHwTypes = nUnitsHwTypes.map(hwt => Object.freeze(hwt))
                return true
            }

            nUnitsHwTypes.forEach(function (nUnitsHwType) {
                let i = state.unitsHwTypes.findIndex(hwt => (hwt.id == nUnitsHwType.id))
                if(i<0) {
                    state.unitsHwTypes.push(Object.freeze(nUnitsHwType))
                }else
                if(!state.unitsHwTypesFullLoad || state.unitsHwTypes[i][changedField] != nUnitsHwType[changedField]) {
                    state.unitsHwTypes[i] = Object.freeze({...state.unitsHwTypes[i], ...nUnitsHwType})
                }
            })
        },
        updateUnitsHwType(state, nUnitsHwType) {
            let i = state.unitsHwTypes.findIndex(hwt => (hwt.id == nUnitsHwType.id))
            if(i<0) {
                state.unitsHwTypes.push(Object.freeze(nUnitsHwType))
            }else
            if(!state.unitsHwTypesFullLoad || state.unitsHwTypes[i][changedField] != nUnitsHwType[changedField]) {
                state.unitsHwTypes[i] = Object.freeze({...state.unitsHwTypes[i], ...nUnitsHwType})
            }
        },
        deleteUnitsHwType(state, id) {
            let i = state.unitsHwTypes.findIndex(t => (t.id == id))
            if(i > 0) {
                state.unitsHwTypes.splice(i, 1)
            }
        },
        clearUnitsHwTypes(state) {
            state.unitsHwTypes = [];
            state.unitsHwTypesFullLoad = false
        },
    },
    getters:{
        isUnitsHwTypesFullLoad(state) {
            return state.unitsHwTypesFullLoad
        },
        getUnitsHwTypes(state){
            return state.unitsHwTypes
        },
        getUnitsHwTypesByType(state){
            return state.unitsHwTypes.reduce((types, ut) => {
                types[ut.hw_type] = ut.hw_title || ut.hw_type
                return types
            },{})
        },
        getHwTypesByType(state){
            return state.unitsHwTypes.reduce((types, ut) => {
                types[ut.hw_type] = {...ut}
                delete types[ut.hw_type].hw_type
                return types
            },{})
        }

    }
}