// import Vue from 'vue'
//import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
//const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}

export default {

    state: {
        locatorUnitsIds: [],
        locatorTokenParams: {},
    },
    actions: {
        fetchLocatorAppUser({commit, dispatch}, args) {
            if(!args.token) {
                return false;
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorAppUser', time: Date.now() / 1000})
            this.$api.locator.getAppUser(args)
                .then((resp) => {
                    commit('updateAppUser', resp.data || {})
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorAppUser', inprogress: false})
                });
        },
        fetchLocatorUnits({commit, dispatch}, args) {
            if(!args.token) {
                return false;
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorUnits', time: Date.now() / 1000})
            this.$api.locator.getUnit(args)
                .then((resp) => {
                    commit('updateUnits', resp.data || [])
                    commit('setLocatorUnitsIds', resp.data || [])
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorUnits', inprogress: false})
                });
        },
        fetchLocatorUnitsLmsgs({commit, dispatch}, args) {
            if(!args.token) {
                return false;
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorUnitsLmsgs', time: Date.now() / 1000})
            this.$api.locator.getUnitLmsg(args)
                .then((resp) => {
                    commit('updateUnitsLmsgs', resp.data || [])
                    // this.setUnitsShowOnMap({showOnMap: true, })
                    // this.setMapCenter(this.lmsg?.latlng)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorUnitsLmsgs', inprogress: false})
                });
        },
        fetchLocatorWeather({commit, dispatch}, args) {
            if(!args.token) {
                return false;
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorWeather', time: Date.now() / 1000})
            this.$api.locator.getUnitWeather(args)
                .then((resp) => {
                    commit('updateWeathers', resp.data)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchLocatorWeather', inprogress: false})
                });
        },
        checkToken({commit, dispatch}, args) {
            if(!args?.lite) dispatch('setLastCall', {name: 'checkToken', time: Date.now() / 1000})
            this.$api.locator.checkToken(args)
                .then((resp) => {
                    commit('setLocatorTokenParams', resp.data)
                    if(resp.data.error) {
                        //
                    } else {
                        //
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'checkToken', inprogress: false})
                });
        }
    },
    mutations: {
        setLocatorUnitsIds(state, units) {
            units = units.map(u => +u.id)
            state.locatorUnitsIds = units
        },
        setLocatorTokenParams(state, params) {
            state.locatorTokenParams = {...state.locatorTokenParams, ...params}
            if(params.error) state.locatorUnitsIds = []
        },
    },
    getters: {
        getLocatorUnitsIds(state) {
            return state.locatorUnitsIds
        },
        getLocatorTokenParams(state) {
            return state.locatorTokenParams
        }
    }
}
