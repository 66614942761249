import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {

    state: {
        trips_index: [],
        trips: [],
    },
    actions: {
        async fetchTrips4Date({ commit, getters }, args) {
            args = {unitId: '', date: '', ...args}
            if(args.date == 'today') args.date = getters.getDateToday
            let isToday = (args.date == getters.getDateToday)
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            // if today come throw 00h - it doesn't work (index ???)
            // let trip = getters.getTrips.find(t => (t.unit_id == args.unitId && t.date == args.date))
            // let record = getters.getTripsIndex.find(i => i.date == args.date &&  i.unit_id == args.unitId  && (!i.timeout || i.timeout > (Date.now() / 1000)))
            // if(trip && args.date !== getters.getDateToday) {
            //     return
            // }
            let params = {withAddress: 1, ...args.params}
            this.$api.trips.get4Date(args.unitId, args.date, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTrips', response.data)
                        commit('setTripsIndex', {
                            ...args,
                            count: response.data.length,
                            timeout: isToday ? ((Date.now() / 1000) + consts.indexTimeout.trips) : false
                        })
                        if (!args.unitId && response.data.length) {
                            // debugger
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchTrips4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let trip = getters.getTrips.find(t => (t.unit_id == args.unitId && t.time_start?.utc==args.from && t.time_end?.utc==args.to))
            if(trip){
                return
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.trips.get4Period(args.unitId, args.from, args.to, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTrips', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        removeTripsIndex(state) {
            state.trips_index =
                state.trips_index.filter(i => !i.timeout || i.timeout > (Date.now() / 1000))
        },
        setTripsIndex(state, params) {
            let i = state.trips_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if(i < 0) {
                state.trips_index.push(Object.freeze({...params}))
            } else {
                state.trips_index[i] = Object.freeze({...state.trips_index[i], ...params})
            }
        },
        storeTrips(state, nTrips) {
            nTrips = nTrips
                .filter(t => !state.trips.find(st => {
                    return st.unit_id == t.unit_id
                        && st.time_start?.utc == t.time_start?.utc
                        // && st.time_end?.utc == t.time_end?.utc
                }))
                .map(t => Object.freeze(t))

            // if(nTrips.length) state.trips.push(...nTrips)
            if(!nTrips.length) return false
            if(!state.trips.length){
                state.trips = nTrips
                return true
            }
            state.trips.push(...nTrips)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nTrips)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.trips.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearTrips(state){
            state.trips = [];
            state.trips_index = [];
        },
    },
    getters: {
        getTripsIndex(state){
            return state.trips_index
        },
        getTrips(state){
            return state.trips
        },
        getTripsByIds(state){
            return state.trips.reduce((tripsByIds, trip) => {
                tripsByIds[trip.id] = trip
                return tripsByIds
            }, {})
        },
    }
}
