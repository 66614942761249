// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = [
    {
        path: '/dashboard-gauges',
        name: consts.routerNames.dashboard_gauges.main,
        meta: {
            mainRouteName: consts.routerNames.dashboard_gauges.main,
            list: 'UnitsList',
            hideMap: true
        },
        component: () => import('@/views/prometheus/portal/DashboardGaugesPage.vue')
    },
];

export default routes;