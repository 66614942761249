// import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {

    state: {
        tracks_index: [],
        tracks: [],
    },
    actions: {
        async fetchTracks4Date({ commit, getters }, args) {
            args = {unitId: '', date: '', ...args}
            if(args.date == 'today') args.date = getters.getDateToday
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            let index = getters.getTracksIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
            if(index){
                return
            }
            this.$api.tracks.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTracks', response.data)
                        commit('setTracksIndex', {...args, count: response.data.length})
                        if (!args.unitId && response.data.length) {
                            // debugger
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchTracks4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let track = getters.getTracks.find(t => (t.unit_id == args.unitId && t.timeFrom==args.from && t.timeTo==args.to))
            if(track){
                return
            }
            this.$api.tracks.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTracks', [response.data])//???
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setTracksIndex(state, params) {
            let i = state.tracks_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if(i < 0) {
                state.tracks_index.push(Object.freeze({...params}))
            } else {
                state.tracks_index[i] = Object.freeze({...state.tracks_index[i], ...params})
            }
        },
        storeTracks(state, nTracks) {
            nTracks = nTracks
                .filter(t => !state.tracks.find(st => {
                    return st.unit_id == t.unit_id
                        && st.timeFrom == t.timeFrom
                        && st.timeTo == t.timeTo
                }))
                .map(t => Object.freeze(t))

            // if(nTracks.length) state.tracks.push(...nTracks)
            if(!nTracks.length) return false
            if(!state.tracks.length){
                state.tracks = nTracks
                return true
            }
            state.tracks.push(...nTracks)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nTracks)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.tracks.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearTracks(state){
            state.tracks = [];
            state.tracks_index = [];
        },
    },
    getters: {
        getTracksIndex(state){
            return state.tracks_index
        },
        getTracks(state){
            return state.tracks
        },
    }
}
