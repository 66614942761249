<template>
    <section class="section section_type_list is_new-design"
             :class="{'section_status_open': $consts.app.$isIntuit ? (!sectionInfoByRoute && showMainList) : showMainList}"
    >

        <SectionUserHead v-if="hasList && $consts.app.$isIntuit" />

        <!--- Section body --->
        <keep-alive :include="keepAliveComponents">
            <component :is="list" />
        </keep-alive>
    </section>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import {mapGetters} from "vuex";
    import SectionUserHead from "@/components/app/SectionUserHead.vue";
    import emptyList from "@/components/app/emptyList.vue";

    export default {
        setup() {
            return {
                keepAliveComponents: ['UnitsList']
            }
        },
        name: "SectionList",
        props: [],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ]),
            routeMeta() {
                return this.$route.meta
            },
            list() {
                return this.$route.meta.list || 'emptyList'
            },
            showMainList() {
                return !this.$route?.params?.noList
            },
            hasList(){
                return !!this.$route.meta.list
            },
            sectionInfoByRoute() {
                return this.routeMeta?.sectionInfoByRoute
            }
        },
        components: {
            SectionUserHead,

            emptyList,

            'UnitsList':  defineAsyncComponent(() => import('@/components/units/UnitsList.vue')),
            'TracksList':  defineAsyncComponent(() => import('@/components/tracks/TracksList.vue')),
            'ReportsList':  defineAsyncComponent(() => import('@/components/reports/ReportsList.vue')),
            'GeoitemsList':  defineAsyncComponent(() => import('@/components/geoitems/GeoitemsList.intuit.vue')),
            'NotificationsList':  defineAsyncComponent(() => import('@/components/notifications/NotificationsList.vue')),
            'NotificationsEventsList':  defineAsyncComponent(() => import('@/components/notifications_events_history/NotificationsEventsList.vue')),

            'RoutesList':  defineAsyncComponent(() => import('@/components/routes/RoutesList.vue')),
            'PersonnelList':  defineAsyncComponent(() => import('@/components/personnel/PersonnelList.vue')),
            'PassengersList':  defineAsyncComponent(() => import('@/components/passengers/PassengersList.vue')),
            'FuelList':  defineAsyncComponent(() => import('@/components/fuel/FuelList.vue')),
            'MaintenanceList':  defineAsyncComponent(() => import('@/components/maintenance/MaintenanceList.vue')),
            'AlarmsList':  defineAsyncComponent(() => import('@/components/alarms/AlarmsList.vue')),
            'VideoList':  defineAsyncComponent(() => import('@/components/video/VideoList.vue')),
            'FuelCardsList':  defineAsyncComponent(() => import('@/components/fuel_cards/FuelCardsList.vue')),

            //prometheus
            // 'UnitsList_prometheus':  defineAsyncComponent(() => import('@/components/units/UnitsList.vue')),
            // 'TracksList_prometheus':  defineAsyncComponent(() => import('@/components/tracks/TracksList.vue')),
            // 'ReportsList_prometheus':  defineAsyncComponent(() => import('@/components/reports/ReportsList.vue')),
            // 'GeoitemsList_prometheus':  defineAsyncComponent(() => import('@/components/geoitems/GeoitemsList.intuit.vue')),
            // 'NotificationsList_prometheus':  defineAsyncComponent(() => import('@/components/notifications/NotificationsList.vue')),
            // 'NotificationsEventsList_prometheus':  defineAsyncComponent(() => import('@/components/notifications_events_history/NotificationsEventsList.vue')),
            //
            // 'RoutesList_prometheus':  defineAsyncComponent(() => import('@/components/routes/RoutesList.vue')),
            // 'PersonnelList_prometheus':  defineAsyncComponent(() => import('@/components/personnel/PersonnelList.vue')),
            // 'PassengersList_prometheus':  defineAsyncComponent(() => import('@/components/passengers/PassengersList.vue')),
            // 'FuelList_prometheus':  defineAsyncComponent(() => import('@/components/fuel/FuelList.vue')),
            // 'MaintenanceList_prometheus':  defineAsyncComponent(() => import('@/components/maintenance/MaintenanceList.vue')),
            // 'AlarmsList_prometheus':  defineAsyncComponent(() => import('@/components/alarms/AlarmsList.vue')),
            // 'VideoList_prometheus':  defineAsyncComponent(() => import('@/components/video/VideoList.vue')),
            // 'FuelCardsList_prometheus':  defineAsyncComponent(() => import('@/components/fuel_cards/FuelCardsList.vue')),

            //admin
            // 'UsersList': defineAsyncComponent(() => import('@/components/users/UsersList.vue')),
            // 'UnitsList': defineAsyncComponent(() => import('@/components/units/UnitsList.vue')),
            // 'KeysList':  defineAsyncComponent(() => import('@/components/keys/KeysList.vue')),
            // 'ActivationsList': defineAsyncComponent(() => import('@/components/activations/ActivationsList.vue')),
        },
    }
</script>

<style scoped>

</style>