// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = [
    {
        path: '/personnel',
        name: consts.routerNames.personnel.main,
        meta: {
            list: 'PersonnelList',
            mainRouteName: consts.routerNames.personnel.main,
            showMap: true
        },
        component: () => import('@/views/prometheus/portal/PersonnelPage.vue')
    },
];

export default routes;