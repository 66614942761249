// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = [
    {
        path: '/fuel_cards',
        name: consts.routerNames.fuel_cards.main,
        meta: {
            list: 'FuelCardsList',
            mainRouteName: consts.routerNames.fuel_cards.main,
            showMap: true
        },
        component: () => import('@/views/prometheus/portal/FuelCardsPage.vue')
    },
];

export default routes;