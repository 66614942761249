// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps/*, hasFilter*/} from "@/lib/lib";
import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        unitsKeysOrdersFullLoad: true,
        unitsKeysOrders: [],
    },
    actions: {
        async fetchUnitsKeysOrders/*all*/({ commit, dispatch, getters }, args) {
            return new Promise((resolve, reject) => {
            if(!getters.apiToken) {
                return reject(false)
            }
            dispatch('setLastCall', {name: 'fetchUnitsKeysOrders', time: Date.now() / 1000})

            // let fullList = !hasFilter(args)
            const params = getApiProps('keysorders', args)
                
            this.$api.unitskeysorders.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data?.error) {
                        commit('updateUnitsKeysOrders', response.data)
                        // if(!fullList) commit('updateUnitsKeysOrders', response.data)
                        // else commit('updateUnitsKeysOrdersFullList', response.data)
                        resolve(true)
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(false)
                })
            })
            .finally(() => {
                dispatch('setLastCall', {name: 'fetchUnitsKeysOrders', inprogress: false})
            });
        },
        async fetchUnitsKeysOrdersAll({dispatch, commit, getters}) {
            if (!getters.apiToken) {
                return false
            }
            //dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', time: Date.now() / 1000})
            dispatch('fetchUnitsKeysOrders', {lite: true})
                .then((resp) => {
                    if (resp) {
                        let count = getters.unitsKeysOrders.length
                        let pages = Math.ceil(count/consts.querySettings.pageSize)
                        let fetch = range(pages).map(i => {
                            let page = i+1;
                            return new Promise((resolve) =>
                                setTimeout(async () => resolve(await dispatch('fetchUnitsKeysOrders', {page})), page*100)
                            )
                        });
                        Promise.all(fetch)
                            .then(() => {
                                if(fetch.length) commit('setUnitsKeysOrdersFullLoad', true)
                            });
                    }
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', inprogress: false})
                });
        },
        async fetchUnitsKeysOrdersChanged({dispatch, getters}, args) {
            if (!getters.apiToken) {
                return false
            }
            args = {...consts.querySettings.filter, ...args}
            dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', time: Date.now() / 1000})
            dispatch('fetchUnitsKeysOrders', args)
                .then(() => {
                    //dispatch('fetchUnitsKeys', {fields: 'id',expand: ''})
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsKeysOrdersChanged', inprogress: false})
                });
        },
        async fetchUnitsKeysOrder({ commit, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                const params = getApiProps('keysorders', args)
                this.$api.unitskeysorders.find(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        async saveUnitsKeysOrder({ dispatch }, key) {
            let fn = (key.id) ? 'updateUnitsKeysOrder' : 'createUnitsKeysOrder'
            return dispatch(fn, key);
        },
        async createUnitsKeysOrder({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keysorders')
                this.$api.unitskeysorders.create(key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeys4Order', {orderId: response.data.id})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnitsKeysOrder({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('keysorders')
                this.$api.unitskeysorders.update(key.id, key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data?.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeys4Order', {orderId: response.data.id})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitsKeysOrder({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitskeysorders.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data?.error)) {
                            //commit('deleteUnitsKeysOrder', id)
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeys4Order', {orderId: response.data.id})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async transferKeys({ dispatch, commit }, order){
            return new Promise((resolve, reject) => {
                const params = getApiProps('keysorders')
                this.$api.unitskeysorders.transfer(order, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data?.error) {
                            let orderId = order?.orderId || response?.data?.id || 0
                            commit('updateUnitsKeysOrder', response.data)
                            if (orderId) dispatch('fetchUnitsKeys4Order', {orderId})
                            //???
                            dispatch('fetchUnitsKeysOrdersChanged')
                            dispatch('fetchUnitsKeysChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        calcUnitsKeysOrdersTimeLeft({ commit }){
            commit('calcUnitsKeysOrdersTimeLeft')
        },

        //sayHello() {}
    },
    mutations: {
        setUnitsKeysOrdersFullLoad(state, FullLoad) {
            state.unitsKeysOrdersFullLoad = state.unitsKeysOrdersFullLoad || FullLoad
        },
        updateUnitsKeysOrders(state, nOrders) {
            if(!state.unitsKeysOrders.length) {
                state.unitsKeysOrders = nOrders.map(o => Object.freeze(o))
                return true
            }

            // let nId = nOrders.map(nO => nO.id)
            // let orders = state.unitsKeysOrders.filter(o => nId.includes(o.id) )
            // if(orders.length !== state.unitsKeysOrders.length) {
            //     state.unitsKeysOrders = orders.map(o => Object.freeze(o))
            // }
            // console.log('updateUnitsKeysOrders', state.unitsKeysOrders.length)
            nOrders.forEach(function (nOrder) {
                let i = state.unitsKeysOrders.findIndex(o => (o.id == nOrder.id))
                if(i<0) {
                    state.unitsKeysOrders.push(Object.freeze(nOrder))
                }else
                if (!state.unitsKeysOrdersFullLoad || state.unitsKeysOrders[i][changedField] != nOrder[changedField]) {
                    state.unitsKeysOrders[i] = Object.freeze({...state.unitsKeysOrders[i], ...nOrder})
                }
            })
        },
        updateUnitsKeysOrder(state, nOrder) {
            let i = state.unitsKeysOrders.findIndex(o => (o.id == nOrder.id))
            if(i<0) {
                state.unitsKeysOrders.push(Object.freeze(nOrder))
            }else
            if (!state.unitsKeysOrdersFullLoad || state.unitsKeysOrders[i][changedField] != nOrder[changedField]) {
                state.unitsKeysOrders[i] = Object.freeze({...state.unitsKeysOrders[i], ...nOrder})
            }
        },
        deleteUnitsKeysOrder(state, id) {
            let i = state.unitsKeysOrders.findIndex(o => (o.id == id))
            if(i > 0) {
                state.unitsKeysOrders.splice(i, 1)
            }
        },

        calcUnitsKeysOrdersTimeLeft(state){
            console.error('!!!calcUnitsKeysOrdersTimeLeft')
            state.unitsKeysOrders.forEach((order,i) => {
                order = {...order}
                order.time__left--
                state.unitsKeysOrders[i] = Object.freeze(order)
            })
        },
        clearUnitsKeysOrders(state) {
            state.unitsKeysOrders = []
            state.unitsKeysOrdersFullLoad = false
        }

    },
    getters: {
        isUnitsKeysOrdersFullLoad(state) {
            return state.unitsKeysOrdersFullLoad
        },
        unitsKeysOrders(state){
            return state.unitsKeysOrders
        },
        unitsKeysOrdersByIds(state){
            return state.unitsKeysOrders.reduce((ordersByIds, o) => {
                ordersByIds[o.id] = o
                return ordersByIds
            }, {})
        },
    }
}
