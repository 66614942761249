import { createStore } from 'vuex'
import consts from "@/consts";

import app from './modules/app'
import map from './modules/map'
import security from './modules/security'
import access_rights from './modules/access-rights'

import users from './modules/users'
import customize from './modules/customize'
import units from './modules/units'
import units_lmsgs from './modules/units-lmsgs'
import video_statuses from './modules/video_statuses'
import units_keys from './modules/units_keys'
import units_keys_orders from './modules/units_keys_orders'
import unit_locator from './modules/unit-locator'
import geozones from './modules/geozones'
import geopoints from './modules/geopoints'
import notifications from './modules/notifications'
import notifications_history from './modules/notifications_history'

import products from "./modules/products";
import units_hwtypes from "./modules/units_hwtypes";

import trips from './modules/trips'
import parkings from './modules/parkings'
import stops from './modules/stops'
import events from './modules/events'
import video_events from './modules/video_events'
import video_requests from './modules/video_requests'
import video_records from './modules/video_records'
import tracks from './modules/tracks'

import weather from "./modules/weather";

import reports_templates from "./modules/reports_templates";
import reports from "./modules/reports";
import statistics from "./modules/statistics";

export default createStore({
    state: {
        fetchByPage: [],
        last_call: {},
        theme: consts.themes.dark,
    },
    getters: {
        lastCall(state){
            return state.last_call
        },
        getFetchByPage(state){
            return state.fetchByPage
        },
        getTheme(state) {
            return state.theme
        }
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme
            let key = [process.env.VUE_APP_PRODUCT , 'theme'].join('.')
            localStorage.setItem(key, theme);
        },
        setLastCall(state, args){
            let name = args?.name || ''
            let time = 0
            if(args?.time){
                time = args.time + consts.api.defaults.auth.timeout
            } else
            if(args?.inprogress === false){
                time = state.last_call[args.name]
                time -= consts.api.defaults.auth.timeout
            }
            state.last_call[name] = time
        },
        addFetchFnsByPage(state, fetchFns){
            if(!fetchFns) {
                state.fetchByPage = [];
                return;
            }
            if(!Array.isArray(fetchFns) ) {
                console.error('addFetchFnsByPage', fetchFns)
                return;
            }
            fetchFns.forEach(f => {
                let fetchFnByPage = state.fetchByPage.find(ff => ff.name == f.name && ff.page == f.page)
                if (fetchFnByPage) {
                    fetchFnByPage.params = f.params
                    fetchFnByPage.eachT = f.eachT
                } else {
                    state.fetchByPage.push(f)
                }
            })
        }
    },
    actions: {
        setLastCall({commit}, args){
            commit('setLastCall', args)
        },
        addFetchFnsByPage({commit}, args){
            commit('addFetchFnsByPage', args)
        },
        fetchAppProps({ getters, dispatch }) {
            if(!getters.apiToken) {
                // return
            }
            let dispatches = consts.fetchAppProps.dispatches || []
            dispatches.forEach(dsp => {
                dispatch(dsp.name, dsp.params)
            })
        },
        fetchByTimer({ getters, dispatch }, params) {
            if(!getters.apiToken) {
                // return
            }
            let time = params.time
            let page = params.page
            //by page
            let dispatches = (getters.getFetchByPage || [])
                .filter(dsp => dsp.page == page && dsp.eachT > 0)
            dispatches.forEach(dsp => {
                let lastCall = getters.lastCall[dsp.name] || 0
                if(time>=lastCall+dsp.eachT) {
                    dispatch(dsp.name, dsp.params)
                    //dispatch('setLastCall', {...dsp, time})
                }
            })
            //by time
            dispatches = consts.fetchByTimer.dispatches || []
            dispatches.forEach(dsp => {
                let lastCall = getters.lastCall[dsp.name] || 0
                if(time>=lastCall+dsp.eachT) {
                    dispatch(dsp.name, dsp.params)
                    //dispatch('setLastCall', {...dsp, time})
                }
            })
        },
        removeIndexToday({commit}){
            commit('removeVideoRecordsIndex')
            commit('removeTripsIndex')
            commit('removeParkingsIndex')
        },
        clearAppCache({commit, dispatch}) {
            commit('clearUnitsMaxSpeed')
            commit('clearUnitsLmsgsSpeed')
            dispatch('fetchUnitsAll')
            dispatch('fetchUnitsLmsgs', {force: true, withAddress: 1})

            commit('clearUnitsKeysOrders')
            commit('clearUnitsKeys')

            commit('clearTrips')
            commit('clearParkings')
            commit('clearTracks')

            commit('clearStops')
            commit('clearNotificationsEvents')
            commit('clearEvents')
            commit('clearVideoEvents')
            commit('clearVideoRequests')
            commit('clearVideoRecords')
        },
        clearAllData({commit}) {
            commit('clearUsers')
            commit('clearUnits')
            commit('clearUnitsLmsgs')

            commit('clearUnitsKeysOrders')
            commit('clearUnitsKeys')

            commit('clearTrips')
            commit('clearParkings')
            commit('clearTracks')

            commit('clearStops')
            commit('clearNotificationsEvents')
            commit('clearEvents')
            commit('clearVideoEvents')
            commit('clearVideoRequests')
            commit('clearVideoRecords')
        },
    },
    modules: {
        app,
        map,
        security,
        access_rights,
        users,
        customize,
        units,
        units_lmsgs,
        video_statuses,
        weather,
        units_hwtypes,
        units_keys,
        units_keys_orders,
        unit_locator,
        products,

        statistics,
        reports,
        reports_templates,

        //geoitems,
        geozones,
        geopoints,

        notifications,

        notifications_history,
        trips,
        parkings,
        events,
        video_events,
        video_requests,
        video_records,
        stops,
        tracks,
    }
})
