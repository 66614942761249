// import consts from "@/consts"
import i18n from "@/i18n";
// import {getApiProps} from "@/lib/lib";

export default {

    state: {
        videoRequests_index: [],
        videoRequests: [],
        videoRequest_active: null,
    },
    actions: {
        setVideoRequestActive( { commit } , id) {
            commit('setVideoRequestActive' , id)
        },
        async fetchLastVideoRequests({ commit, dispatch, getters }, args) {
            if(!getters.apiToken) {
                return
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchLastVideoRequests', time: Date.now() / 1000})
            let last = args?.last || '1 minutes'
            let params = {withAddress: 1, ...args.params}
            this.$api.videoRequests.getLast(last, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeVideoRequests', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchLastVideoRequests', inprogress: false})
                });
        },
        async fetchVideoRequests4Date({ commit, dispatch, getters }, args) {
            args = {unitId: '', date: '', ...args}
            if(args.date == 'today') args.date = getters.getDateToday
            if(!getters.apiToken || !(args.date>'')) {//!args.unitId ||
                return
            }

            // if(args.unitId > 0) {
            //     let index = getters.videoRequestsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
            //     if (index) {
            //         return
            //     }
            // }
            if(!args?.lite) {
                if(args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastVideoRequests', time: Date.now() / 1000})
                }
                dispatch('setLastCall', {name: 'fetchVideoRequests4Date', time: Date.now() / 1000})
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.videoRequests.get4Date(args.unitId, args.date, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeVideoRequests', response.data)
                        // commit('setVideoRequestsIndex', {...args, count: response.data.length})
                        // if (!args.unitId && response.data.length) {
                        //     response.data
                        //         .map(e => e.unit_id)
                        //         .filter(arrayFilter_OnlyUnique)
                        //         .forEach(unitId => {
                        //             commit('setVideoRequestsIndex', {...args, unitId, count: 1})
                        //         })
                        // }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    if (!args?.lite) {
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastVideoRequests', inprogress: false})
                        }
                        dispatch('setLastCall', {name: 'fetchVideoRequests4Date', inprogress: false})
                    }
                });
        },
        async fetchVideoRequests4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                return
            }
            // if(args.unitId > 0) {
            //     let request = getters.getVideoRequests.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
            //     if (request) {
            //         return
            //     }
            // }
            let params = {withAddress: 1, ...args.params}
            this.$api.videoRequests.get4Period(args.unitId, args.from, args.to, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeVideoRequests', response.data)
                        // commit('setVideoRequestsIndex', {...args, count: response.data.length})
                        // if (!args.unitId && response.data.length) {
                        //     response.data
                        //         .map(e => e.unit_id)
                        //         .filter(arrayFilter_OnlyUnique)
                        //         .forEach(unitId => {
                        //             commit('setVideoRequestsIndex', {...args, unitId, count: 1})
                        //         })
                        // }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createVideoRequest({ commit/*, dispatch*/ }, videoRequest) {
            return new Promise((resolve, reject) => {
                //const params = getApiProps('videoRequests')
                this.$api.videoRequests.create(videoRequest.unitId, videoRequest)//, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('storeVideoRequests', [response.data])
                            //dispatch('fetchVideoRequests4Date', videoRequest)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setVideoRequestActive(state , id){
            return state.videoRequest_active = id
        },
        setVideoRequestsIndex(state, params) {
            let i = state.videoRequests_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if(i < 0) {
                state.videoRequests_index.push(Object.freeze({...params}))
            } else {
                state.videoRequests_index[i] = Object.freeze({...state.videoRequests_index[i], ...params})
            }
        },
        storeVideoRequests(state, nRequests) {
            if (!state.videoRequests.length) {
                state.videoRequests = nRequests.map(r => Object.freeze(r))
                return true
            }

            nRequests.forEach(function (nRequest) {
                let i = state.videoRequests.findIndex(r => (r.xid == nRequest.xid))
                if (i < 0) {
                    state.videoRequests.push(Object.freeze(nRequest))
                } else {
                    state.videoRequests[i] = Object.freeze({...state.videoRequests[i], ...nRequest})
                }
            })
        },
        clearVideoRequests(state){
            state.videoRequests = [];
            state.videoRequests_index = [];
        },
    },
    getters: {
        getNewVideoRequestsCount(state, getters){
            let lastVisit = getters.getLastVisitNotifications
            if(getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.videoRequests.filter(e => e.time?.utc > lastVisit).length
        },
        getVideoRequestActive(state){
            return state.videoRequest_active
        },
        getVideoRequestsIndex(state){
            return state.videoRequests_index
        },
        getVideoRequestsCount(state){
            return state.videoRequests.length
        },
        getVideoRequests(state){
            return state.videoRequests
        },
        getVideoRequestsI18nMessages(state){
            return state.videoRequests
                .map((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'video_request' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: event.type, xid: event.xid, message};
                })
        },
        getVideoRequestsI18nMessagesById(state){
            return state.videoRequests
                .reduce((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'video_request' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    eventsTextByXid[event.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return eventsTextByXid;
                }, {})
        },
        getVideoRequestsByIds(state){
            return state.videoRequests.reduce((out, request) => {
                out[request.id] = request
                return out
            }, {})
        },
    }
}
