<template>
    <teleport :to="teleport" :disabled="!teleport">
        <vue-final-modal
            :click-to-close="false"
            :esc-to-close="true"
            v-slot="{ params }"
            :modelValue="true"
            classes="modal-container"
            :class="modalClass"
            content-class="modal-content"
        >
            <!--         v-on="$listeners" -->
            <button class="modal__close button button_view_icon button_theme_primary" @click="onCancel" v-if="showCansel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close"/></span> </span>
            </button>
            <span class="modal__title">
                {{ title }}
            </span>
            <div class="modal__content">
                <slot v-if="params" v-bind:params="params"></slot>
            </div>
            <div class="modal__action">
                <button class="button button_view_base mr__8"
                        :class="btnOkClass"
                        @click.once="onOk()"
                        v-if="showOk"
                >
                    {{ btnOkText || $t('buttons.Ok') }}
                </button>
                <button class="button button_view_base modal-close-btn"
                        :class="btnCanselClass"
                        @click.once="onCancel()"
                        v-if="showCansel"
                >
                    {{ btnCanselText || $t('buttons.Cancel') }}
                </button>
            </div>
        </vue-final-modal>
    </teleport>
</template>

<script>
// import { VueFinalModal } from 'vue-final-modal'
export default {
    name: 'CustomModal',
    inheritAttrs: false,
    emits: ['confirm', 'cancel'],
    props: {
        'teleport': {
            type: String,
            default: () => 'body'
        },
        'title': String,
        'buttons': Array,
        // 'params',
        'confirm_text': String,
        'cancel_text': String,
        'fnOk': Function,
        'fnCancel': Function,
        'modalClass': [String],
        'btnOkText': String,
        'btnCanselText': String,
        'btnOkClass': {
            type: String,
            default: () => 'button_theme_danger'
        },
        'btnCanselClass': {
            type: String,
            default: () => 'button_theme_neutral'
        }
    },
    data() {
        return {}
    },
    computed:{
        showCansel(){
            return !!this.buttons.find(b => b.id == 'cancel')
        },
        showOk(){
            return !!this.buttons.find(b => b.id == 'ok')
        },
    },
    methods:{
        // confirm() {
        //     if( typeof this.fnOk == 'function'){
        //         this.fnOk()
        //     }
        //     this.showPopup = false
        // },
        // cancel() {
        //     this.showPopup = false
        // },

        onOk(){
            this.buttons.find(b => b.id == 'ok').fn()//???
            //close() //???
        },
        onCancel(){
            this.buttons.find(b => b.id == 'cancel').fn()//???
            //close() //???
        }
    }
    // comments: { 'vue-final-modal': VueFinalModal },
}
</script>

<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_hide_close-btn .modal-close-btn{
    display: none;
}
::v-deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
.modal-300 ::v-deep( .modal-content) {
    max-width: 500px;
}
</style>
