// import consts from "@/consts"
import {arrayFilter_OnlyUnique} from "@/lib/lib";
import i18n from "@/i18n";

export default {

    state: {
        events_index: [],
        events: [],
        event_active: null,
    },
    actions: {
        setEventActive( { commit } , id) {
            commit('setEventActive' , id)
        },
        async fetchLastEvents({ commit, dispatch, getters }, args) {
            if(!getters.apiToken) {
                return
            }
            if(!args?.lite) dispatch('setLastCall', {name: 'fetchLastEvents', time: Date.now() / 1000})
            let last = args?.last || '1 minutes'
            let params = {withAddress: 1, ...args.params}
            //if(!args.force) params.lastTimes = getters.getEventsLastTimeByUnitIds
            this.$api.events.getLast(last, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeEvents', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    if(!args?.lite) dispatch('setLastCall', {name: 'fetchLastEvents', inprogress: false})
                });
        },
        async fetchEvents4Date({ commit, dispatch, getters }, args) {
            args = {unitId: '', date: '', ...args}
            if(args.date == 'today') args.date = getters.getDateToday
            if(!getters.apiToken || !(args.date>'')) {//!args.unitId ||
                return
            }
            if(args.unitId > 0) {
                let index = getters.getEventsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                if (index) {
                    return
                }
            }
            if(!args?.lite && args.date == getters.getDateToday) {
                dispatch('setLastCall', {name: 'fetchLastEvents', time: Date.now() / 1000})
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.events.get4Date(args.unitId, args.date, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeEvents', response.data)
                        if (!params.limit || response.data.length < params.limit) {
                            commit('setEventsIndex', {...args, count: response.data.length})
                            if (!args.unitId && response.data.length) {
                                response.data
                                    .map(e => e.unit_id)
                                    .filter(arrayFilter_OnlyUnique)
                                    .forEach(unitId => {
                                        commit('setEventsIndex', {...args, unitId, count: 1})
                                    })
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    if(!args?.lite && args.date == getters.getDateToday) {
                        dispatch('setLastCall', {name: 'fetchLastEvents', inprogress: false})
                    }
                });
        },
        async fetchEvents4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                return
            }
            if(args.unitId > 0) {
                let event = getters.getEvents.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                if (event) {
                    return
                }
            }
            let params = {withAddress: 1, ...args.params}
            this.$api.events.get4Period(args.unitId, args.from, args.to, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeEvents', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setEventActive(state , id){
            return state.event_active = id
        },
        setEventsIndex(state, params) {
            let i = state.events_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if(i < 0) {
                state.events_index.push(Object.freeze({...params}))
            } else {
                state.events_index[i] = Object.freeze({...state.events_index[i], ...params})
            }
        },
        storeEvents(state, nEvents) {
            nEvents = nEvents
                .filter((e,i,_events) => (i === _events.findIndex(_e => _e.xid == e.xid)))
                .filter(e => !state.events.find(se => se.xid == e.xid))
                .map(e => Object.freeze(e))

            // if(nEvents.length) state.events.push(...nEvents)
            if(!nEvents.length) return false
            if(!state.events.length){
                state.events = nEvents
                return true
            }
            state.events.push(...nEvents)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nEvents)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.events.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearEvents(state){
            state.events = [];
            state.events_index = [];
        },
    },
    getters: {
        getNewEventsCount(state, getters) {
            let lastVisit = getters.getLastVisitNotifications
            if(getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.events.filter(e => e.time?.utc > lastVisit).length
        },
        getEventActive(state){
            return state.event_active
        },
        getEventsIndex(state){
            return state.events_index
        },
        getEvents(state){
            return state.events
        },
        getEventsI18nMessages(state) {
            return state.events
                .map((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'events' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: event.type, xid: event.xid, message};
                })
        },
        getEventsI18nMessagesByXid(state) {
            return state.events
                .reduce((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'events' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    eventsTextByXid[event.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return eventsTextByXid;
                }, {})
        },
        getEventsLastTimeByUnitIds(state) {
            return state.events
                //.filter(e => e?.time?.utc >= getters.getTimeToday)
                .reduce((timeByUnitIds, event) => {
                    timeByUnitIds[event.unit_id] = event?.time?.utc > timeByUnitIds[event.unit_id] ? event?.time?.utc : timeByUnitIds[event.unit_id]
                    return timeByUnitIds
                }, {})
        },
    }
}
