

export default {
    state: {
        tokens: {},
        httpErrors: {},
    },
    actions: {
        async loginByToken({ commit }, access_token) {
            await this.$api.auth.login({access_token})
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateTokens', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async refreshTokens({ commit, getters }, token) {
            if(token>''){
                await commit('setToken', token)
            }
            token = token>'' ? token : getters.refreshToken
            if(!(token>'')){
                return false
            }
            await this.$api.auth.refreshTokens()
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateTokens', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async updateTokens({ commit, dispatch }, tokens) {
            if(!tokens){
                return false
            }
            await commit('updateTokens', tokens)
            dispatch('fetchAppUser', {})
        },
        calcTokensExpire({ commit }, time) {
            commit('calcTokensExpire', time)
        }
    },
    mutations: {
        addHttpResponseStatus(state, status){
            if(status < 400) return
            //error
            if(!state.httpErrors[status]){
                state.httpErrors[status] = 0
            }
            state.httpErrors[status] += 1
        },
        setToken(state, token) {
            if(!(token>'')) return false
            let t = Math.round(Date.now() / 1000)
            state.tokens = {
                token_type: 'Bearer',
                access_token: token,
                expires_in: 30,
                expires_in_time: t+30,
                // refresh_token: token,
                // refresh_expires_in: 30,
                // refresh_expires_in_time: t+30,
            }
        },
        updateTokens(state, tokens) {
            tokens = tokens || {}
            state.tokens = tokens
            //if(tokens.access_token>'')
            localStorage.setItem(process.env.VUE_APP_PRODUCT+'.authtoken', tokens.access_token || '')
            if(tokens.refresh_token>'')
                localStorage.setItem(process.env.VUE_APP_PRODUCT+'.authtoken', tokens.refresh_token || '')
        },
        calcTokensExpire(state, time){
            if(state.tokens) {
                state.tokens.expires_in = state.tokens.expires_in_time - time
                if(state.tokens.expires_in <= 0) state.tokens.access_token = ''
                state.tokens.refresh_expires_in = state.tokens.refresh_expires_in_time - time
            }
        },
        resetAccessToken(state){
            if(state.tokens) state.tokens.access_token = ''
        },
    },
    getters: {
        getHttpErrors(state){
            return state.httpErrors
        },
        getTokens(state) {
            return state.tokens
        },
        getHeaderAuthorization(state) {
            let type = (state.tokens || {}).token_type || 'Bearer'
            type = type.charAt(0).toUpperCase() + type.slice(1)
            let token = (state.tokens || {}).access_token || 'dummy'
            return `${type} ${token}`
        },
        refreshToken(state) {
            return state.tokens.refresh_token
        },
        apiTokenExpiresIn(state) {
            return state.tokens.expires_in || -1
        },
        apiToken(state) {
            let t = state.tokens.expires_in
            if(t < 300 && t > 0){
                console.warn('this token will expire soon!!!', t)
                //actions.refreshTokens
            } else
            if(t < 0){
                console.warn('this token expired !!!', t)
            }
            return state.tokens.access_token
        },
        isGuest(state) {
            return !(state.tokens.access_token && state.tokens.access_token >'')
        },
    }
}
