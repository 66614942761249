<template>
    <header class="header">
        <div class="header__wrap">
            <div class="header__content">
                <div class="header__logo">
                    <img :src="(getAppUser.head__logo || {}).url || require('@/assets/img/empty1px.png')">
                </div>
                <ul class="header__list">
                    <li class="header__item">
                        <CheckboxControl
                            :control-class="''"
                            :label="'Dark theme'"
                            v-model="theme"
                            v-if="!$isAdminSite"
                        />
                    </li>
                    <li class="header__item">
                        <span>
                            <SvgIcon name="user" />
                        </span>
                        <span>
                            {{getAppUser.login}}
                        </span>
                    </li>
                    <li class="header__item">
                        <button class="button button_view_outline" @click="onLogout">
                            {{ $t('menu.Logout') }}
                            <SvgIcon name="common__exit" class="icon-right"/>
                        </button>
                    </li>
                    <li class="header__item">
                        <button class="button button_icon" @click="displaySectionWidget('UserSettings')">
                            <SvgIcon name="wizard-settings" class="icon" :title="$t('menu.Settings')"/>
                        </button>
                    </li>
                    <template v-if="$consts.app.$isPrometheus && $consts.app.$isPortalSite">
                        <li class="header__item header__item-line"></li>
                        <li class="header__item">
                            <span class="header__item-badge" @click="$router.push({name: $consts.routerNames.notificationsevents.main})">
                                <SvgIcon name="icon-dialog" class="icon"/>
                                <span class="header__item-badge__info" v-show="notifications">{{ notifications }}</span>
                            </span>
                            {{ $t('menu.Notifications') }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "SectionHeader",
    components: {},

    computed:{
        ...mapGetters([
            "getAppUser",
            "getNewEventsCount",
            "getNewNotificationEventsCount",
            "getTimeToday",
            "getLastVisitNotifications",
            "getTheme",
        ]),
        routeMeta() {
            return this.$route.meta
        },
        setRoute() {
            return this.routeMeta.setRoute
        },
        theme: {
            get() {
                return this.getTheme == this.$consts.themes.dark
            },
            set(vl) {
                this.setTheme(vl ? this.$consts.themes.dark : this.$consts.themes.white)
            }
        },
        notifications(){
            let count = this.getNewEventsCount + this.getNewNotificationEventsCount
            let plus = (this.getTimeToday > this.getLastVisitNotifications ? '+' : '')
            return (count || plus) ? count + plus : false
        },
    },
    methods:{
        ...mapMutations([
            "updateTokens",
            "setSectionWidget",
            "setTheme",
        ]),
        ...mapActions([
            "logout",
        ]),

        onLogout(){
            //this.$cookies.set('authtoken', '')
            this.logout()
        },
        displaySectionWidget(component) {
            this.setSectionWidget({
                component: component,
                props: {}
            })
        },
    }
}
</script>

<style scoped>

</style>